import React from "react";
import { useSelector } from "react-redux";
import TableInfo from "../../../../components/TableInfo";
import { GRID_CARD_LINK } from "../../../../router/links";
import { isCustomPageSelector } from "../../../../ducks/profile";

const ShippingOrders = ({ form = {} }) => {
    const customPage = useSelector(state => isCustomPageSelector(state));

    return (
        <div className="tabs-card_table">
            <TableInfo 
                key="shippingOrders"
                name="orders"
                className='compact-container'
                headerRow={customPage.shippingOrdersColumns}
                list={form.orders}
                cardLink={GRID_CARD_LINK}
                isSubTable={true}
                canEdit={false}
            />
        </div>
    );
};

export default ShippingOrders;