import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid } from "semantic-ui-react";
import FormField from "../customGrid/components/formField";
import { SELECT_TYPE } from "../../constants/columnTypes";
import { dataUploadingRequest, errorsDataSelector, inProgressDataSelector } from "../../ducks/tariffsLoader";
import { SETTINGS_TYPE_EDIT } from "../../constants/formTypes";

const TariffsLoaderAbie = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const fileUploader = useRef(null);

    let [form, setForm] = useState({});

    const inProgress = useSelector(inProgressDataSelector);
    const errors = useSelector(errorsDataSelector);

    const onChange = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        };
        setForm(newForm);
    };

    const uploadFile = () => {
        fileUploader && fileUploader.current.click();
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);

        dispatch(
            dataUploadingRequest({
                form: data,
                typeApi: 'tariffsAbie',
                mode : form['mode'] && form['mode'].value,
                routeType : form['routeType'] && form['routeType'].value
            })
        );
        fileUploader.current.value = null;
    };

    const onModeChanged = (value) => {
        const newForm = {
            ...form,
            mode: value,
            routeType: null
        };
        setForm(newForm);
    };

    return (
        <div className="container">
            <div className="report">
                <div className="report_params">
                    <Form>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <p>Режим 1. Загрузка значения из файла под каждый вид оборудования без расчета ставок.</p>
                                    <p>Режим 2. При загрузке происходит внесение дополнительных строк и расчет ставок по фиксированному проценту по указанному базовому оборудованию (Прямая отгрузка Изот 20т, Возкрат Тент 20т) для других видов оборудования.</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} verticalAlign="bottom">
                                <Grid.Column>
                                    <FormField
                                        name="mode"
                                        type={SELECT_TYPE}
                                        form={form}
                                        error={errors && errors['mode']}
                                        text="Режим загрузки"
                                        source="tariffImportingMode"
                                        settings={{ mode: SETTINGS_TYPE_EDIT }}
                                        onChange={(e, { value }) => onModeChanged(value)}
                                        isDisabled={inProgress}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <FormField
                                        name="routeType"
                                        type={SELECT_TYPE}
                                        form={form}
                                        error={errors && errors['routeType']}
                                        text="Тип рейса"
                                        source="routeType"
                                        settings={{ routeType: SETTINGS_TYPE_EDIT }}
                                        onChange={(e, { value }) => onChange('routeType', value)}
                                        isDisabled={inProgress || !form['mode'] || form['mode'].value !== "loadingMode2"}
                                    />
                                </Grid.Column>
                                <Grid.Column textAlign="right">
                                    <input
                                        type="file"
                                        ref={fileUploader}
                                        style={{ display: 'none' }}
                                        onChange={onFilePicked}
                                    />
                                    <Button primary loading={inProgress} onClick={uploadFile}>
                                        {t('data_loading')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            </div>
        </div>
    )
};

export default TariffsLoaderAbie;