import React from 'react';
import CardLayout from '../../components/CardLayout';
import Content from '../dictionaries/customDictionary/cardContent';
import Documents from '../customGrid/components/shared/documents';
import History from '../customGrid/components/shared/history';
import CardContent from '../dictionaries/customDictionary/cardContent';
import { Form, Header } from 'semantic-ui-react';
import { sortFunc } from '../../utils/sort';
import { useSelector } from 'react-redux';
import { profileSelector } from '../../ducks/profile';


const RoleTabs = ({
    t,
    props,
    form,
    columns,
    name,
    id,
    error,
    title,
    canEdit,
    useDocuments,
    useHistory,
    permissions,
    allPermissions,
    actions,
    orderActions,
    shippingActions,
    dictionaryActions,
    roleBacklights,
    allBacklights,
    handlePermissions,
    handleActions,
    handleBacklights,
    onClose,
    onChangeForm,
    actionsFooter,
    actionsHeader,
    openModal,
    onOpenModal,
    loading

}) => {

    const profile = useSelector(state => profileSelector(state));

    const getPanes = () => {
        let baseColumns = columns.filter(column => !column.groupKey);

        let obj = [
            {
                menuItem: 'general info',
                render: () => (
                    <CardContent
                        columns={baseColumns}
                        error={error}
                        form={form}
                        canEdit={canEdit}
                        handleChange={onChangeForm}
                    />
                ),
            },
        ];

        let groups = Array.from(new Set(columns.filter(column => column.groupKey).map((column) => column.groupKey)));
        groups.forEach(groupKey => {
            let groupColumns = columns.filter(column => column.groupKey === groupKey);
            obj.push({
                menuItem: groupKey,
                render: () => (
                    <Content 
                        columns={groupColumns} 
                        error={error} 
                        form={form} 
                        canEdit={canEdit}
                        handleChange={onChangeForm}
                    />
                ),
            });
        });

        obj.push({
            menuItem: 'permissions',
            render: () => (
                <Form className="role-form">
                    {allPermissions.map(group => (
                        <>
                            <Header as='h4'>{group.groupName}</Header>
                            {group.entries.map(permission => (
                                <Form.Field key={permission.code}>
                                    <Form.Checkbox
                                        label={t(permission.name)}
                                        value={permission.code}
                                        checked={
                                            permissions && permissions.includes(permission.code)
                                        }
                                        disabled={
                                            (permissions &&
                                                ([2, 4, 5, 6].includes(permission.code) &&
                                                    !permissions.includes(1))) ||
                                            ([10, 11, 12].includes(permission.code) &&
                                                !permissions.includes(7))
                                        }
                                        onChange={handlePermissions}
                                    />
                                </Form.Field>
                            ))}
                        </>
                    ))}
                    
                </Form>
            ),
        });

        obj.push({
            menuItem: profile.importOrdersTitle,
            render: () => (
                <Form className="role-form">
                    {sortFunc(orderActions, t).map(action => (
                        <Form.Field key={action.value}>
                            <Form.Checkbox
                                label={action.name}
                                value={action.value}
                                checked={actions && actions.includes(action.value)}
                                onChange={handleActions}
                            />
                        </Form.Field>
                    ))}
                </Form>
            ),
        });

        obj.push({
            menuItem: profile.importShippingsTitle,
            render: () => (
                <Form className="role-form">
                    {sortFunc(shippingActions, t).map(action => (
                        <Form.Field key={action.value}>
                            <Form.Checkbox
                                label={action.name}
                                value={action.value}
                                checked={actions && actions.includes(action.value)}
                                onChange={handleActions}
                            />
                        </Form.Field>
                    ))}
                </Form>
            ),
        });

        obj.push({
            menuItem: 'dictionary_actions',
            render: () => (
                <Form className="role-form">
                    {sortFunc(dictionaryActions, t).map(action => (
                        <Form.Field key={action.value}>
                            <Form.Checkbox
                                label={action.name}
                                value={action.value}
                                checked={actions && actions.includes(action.value)}
                                onChange={handleActions}
                            />
                        </Form.Field>
                    ))}
                </Form>
            )
        });

        obj.push({
            menuItem: 'Alerts in your account',
            render: () => (
                <Form className="role-form">
                    {
                        allBacklights.map(item => (
                            <Form.Field key={item.key}>
                                <Form.Checkbox
                                    label={t(item.name)}
                                    value={item.value}
                                    checked={roleBacklights && roleBacklights.includes(item.value)}
                                    onChange={handleBacklights}
                                />
                            </Form.Field>
                        ))
                    }
                </Form>
            )
        });

        if (useDocuments) {
            obj.push({
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        cardId={id}
                        isEditPermissions={canEdit}
                    />
                ),
            });
        }

        if (useHistory) {
            obj.push({
                menuItem: 'history',
                render: () => <History cardId={id} />,
            });
        }

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />

    );
};

export default RoleTabs;
