import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Confirm, Icon, Modal, Popup } from 'semantic-ui-react';
import ArticleTabs from './articleTabs';
import {
    canDeleteSelector,
    canEditSelector,
    cardProgressSelector,
    cardSelector,
    clearDictionaryCard,
    columnsCardSelector,
    deleteDictionaryEntryRequest,
    errorSelector,
    getCardConfigRequest,
    getCardRequest,
    getDictionaryCardDefaultValueRequest,
    progressSelector,
    saveDictionaryCardRequest,
    canUseDocumentsSelector,
    canUseHistorySelector,
    displayNameSelector,
} from '../../../ducks/dictionaryView';
import { clearHistory, getHistoryRequest } from '../../../ducks/history';

const CardNew = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        match = {},
        history,
        location,
        load,
        isModal,
        openModal,
        onClose: onCloseModal,
    } = props;
    const { params = {} } = match;
    const { name = 'articles', id } = params;
    const { state } = location;
    const { defaultForm, columns: propsColumns, copyFromId } = state;

    let [form, setForm] = useState({ ...defaultForm });
    let [confirmation, setConfirmation] = useState({ open: false });
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [dependentFields, setDependentFields] = useState({});

    const columns = useSelector(state =>
        propsColumns ? propsColumns : columnsCardSelector(state, name, id),
    );
    const displayName = useSelector(state => displayNameSelector(state, name));
    const canDelete = useSelector(state => canDeleteSelector(state, name));
    const canEdit = useSelector(state => canEditSelector(state, name));
    const useDocuments = useSelector(state => canUseDocumentsSelector(state, name));
    const useHistory = useSelector(state => canUseHistorySelector(state, name));
    const loading = useSelector(state => cardProgressSelector(state));
    const progress = useSelector(state => progressSelector(state));
    const card = useSelector(state => cardSelector(state));
    const error = useSelector(state => errorSelector(state));

    useEffect(() => {
        id && dispatch(getCardConfigRequest({ id, name }));
        id
            ? dispatch(getCardRequest({ id, name }))
            : copyFromId
                ? dispatch(getCardRequest({ id: copyFromId, name }))
                : dispatch(getDictionaryCardDefaultValueRequest(name));

        return () => {
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(() => {
        if (useHistory) {
            id && dispatch(getHistoryRequest(id));
        }
        
        return () => {
            dispatch(clearHistory());
        };
    }, [useHistory]);

    useEffect(() => {
        if (copyFromId) {
            card.id = null;
            setNotChangeForm(false);
        }
        setForm(form => ({
            ...card,
            ...form,
        }));
    }, [card]);

    useEffect(() => {
        let obj = {};
        columns && columns.length && columns
            .filter(column => column.dependencies && column.dependencies.length)
            .forEach(column => {
                column.dependencies.forEach(item => {
                    obj = {
                        ...obj,
                        [item]: [...(obj[item] || []), column.name],
                    };
                });
            });

        setDependentFields(obj);
    }, [columns]);

    const onOpenModal = () => { };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const title = useMemo(
        () => (id ? `${displayName || t(name)}: ${t('edit_record')}` : `${t(name)}: ${t('new_record')}`),
        [name, id],
    );

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={notChangeForm || progress}
                    loading={progress}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [form, notChangeForm, progress]);

    const handleSave = () => {
        let params = {
            ...form,
        };

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        const callbackConfirmation = model => {
            setConfirmation({
                open: true,
                content: model.confirmationMessage || model.message,
                onCancel: confirmClose,
                onConfirm: () => {
                    confirmClose();
                    dispatch(
                        saveDictionaryCardRequest({
                            params,
                            name,
                            callbackSuccess: () => {
                                load && load(form);
                                onClose();
                            },
                            isConfirmed: true,
                        }),
                    );
                },
            });
        };

        dispatch(
            saveDictionaryCardRequest({
                params,
                name,
                callbackSuccess: () => {
                    load && load(form);
                    onClose();
                },
                isConfirmed: false,
                callbackConfirmation,
            }),
        );
    };

    const handleDelete = () => {
        /* const { id, deleteEntry, name } = this.props;*/

        showConfirmation(
            t('confirm_delete_dictionary'),
            () => {
                confirmClose();
                dispatch(
                    deleteDictionaryEntryRequest({
                        name,
                        ids: [ id ],
                        callbackSuccess: onClose,
                    }),
                );
            },
            confirmClose,
        );
    };

    const getActionsHeader = useCallback(() => {
        return (
            <div>
                {canDelete ? (
                    <Popup
                        content={t('delete')}
                        position="bottom right"
                        trigger={
                            <Button icon onClick={handleDelete}>
                                <Icon name="trash alternate outline" />
                            </Button>
                        }
                    />
                ) : null}
            </div>
        );
    }, []);

    const handleChange = useCallback(
        (event, { name, value, ...prev }) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            let formNew = {
                ...form,
                [name]: value,
            };

            if (dependentFields[name] && dependentFields[name].length) {
                dependentFields[name].forEach(item => {
                    formNew = {
                        ...formNew,
                        [item]: "",
                    }
                })
            }

            const autocompleteFields = columns.filter(
                i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
            );

            if (autocompleteFields && autocompleteFields.length && value) {
                autocompleteFields.forEach(autocompleteField => {
                    formNew = {
                        ...formNew,
                        [autocompleteField.name]: value[autocompleteField.autoComplete[name]] || '',
                    };
                });
            }

            setForm(formNew);
        },
        [notChangeForm, form, dependentFields],
    );

    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = () => {
        isModal
            ? onCloseModal()
            : history.replace({
                pathname: location.state.pathname,
                state: {
                    ...location.state,
                    pathname: location.state.gridLocation
                        ? location.state.gridLocation
                        : location.state.pathname,
                },
            });
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };



    const cardContent = () => {
        const params = {
            props,
            form,
            columns,
            name,
            id,
            error,
            title,
            canEdit,
            useDocuments,
            useHistory,
            onClose: handleClose,
            onChangeForm: handleChange,
            actionsFooter: getActionsFooter,
            actionsHeader: getActionsHeader,
            openModal: openModal,
            onOpenModal: onOpenModal,
            loading
        };

        const getContent = () => { return <ArticleTabs />; };

        return React.cloneElement(getContent(), params);
    };


    return (
        <React.Fragment>
            {cardContent()}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </React.Fragment>
    );
};

export default CardNew;
