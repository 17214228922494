export const OPERATIONAL_REPORT_TYPE = 'operational';
export const REGISTRY_REPORT_TYPE = 'registry';
export const SHIPMENT_REGISTRY_REPORT_TYPE = 'shipment_registry';
export const ALLOCATIONS_SUMMARY_ACTUAL_PLAN_REPORT_TYPE = 'allocations_summary_actual_plan';
export const ALLOCATIONS_PLAN_ACTUAL_INDICATORS_REPORT_TYPE = 'allocations_plan_actual_indicators';
export const DIRECTION_ALLOCATIONS_PLAN_ACTUAL_REPORT_TYPE = 'direction_allocations_plan_actual';
export const FAILURE_REPORT_TYPE = 'failure';
export const REFUSAL_REPORT_TYPE = 'refusal';
export const ORDER_ADDING_TO_TRANSPORTATIONS_REPORT_TYPE = 'order_adding_to_transportations';
export const SHIPPING_DETAILED_COSTS_REPORT_TYPE = 'shipping_detailed_costs';
export const ADDITIONAL_POINTS_COST_REPORT_TYPE = 'additional_points_cost_report';
