import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import FormField from "../formField";
import TableInfo from "../../../../components/TableInfo";
import { columnsCardTypesConfigSelector } from "../../../../ducks/representations";
import { SHIPPINGS_GRID } from "../../../../constants/grids";
import { isCustomPageSelector } from "../../../../ducks/profile";

const ShippingRequests = ({ form = {}, onChange, settings, error }) => {
    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, SHIPPINGS_GRID));
    const customPage = useSelector(state => isCustomPageSelector(state));

    const renderField = (name, columnWidth, params = {}) => {
        if (settings && settings[name] === "hidden") {
            return null;
        }
        return (
            <Grid.Column width={columnWidth}>
                {
                    React.cloneElement(
                        <FormField
                            name={name}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />,
                        {...params}
                    )}
            </Grid.Column>
        );
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row className="field-row">
                    {renderField("optimizationRound", 4)}
                    {renderField("optimizationDate", 4)}
                </Grid.Row>
                <div className="tabs-card_table">
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <TableInfo 
                                key="shippingRequests"
                                className='compact-container'
                                headerRow={customPage.shippingRequestsColumns}
                                list={form.requests}
                                isSubTable={true}
                                canEdit={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </div>
            </Grid>
        </Form>
    );
};

export default ShippingRequests;