import React from 'react';
import Parameters from './articleTabs/parameters';
import PalletTypes from './articleTabs/palletTypes';
import CardLayout from '../../../components/CardLayout';
import Content from '../customDictionary/cardContent';
import History from '../../customGrid/components/shared/history';
import Documents from '../../customGrid/components/shared/documents';

const ArticleTabs = ({
    props,
    form,
    columns,
    name,
    id,
    error,
    title,
    canEdit,
    useDocuments,
    useHistory,
    onClose,
    onChangeForm,
    actionsFooter,
    actionsHeader,
    openModal,
    onOpenModal,
    loading

}) => {

    const getPanes = () => {
        let baseColumns = columns.filter(column => !column.groupKey);

        let obj = [
            {
                menuItem: 'general info',
                render: () => (
                    <Content
                        columns={baseColumns}
                        error={error}
                        form={form}
                        canEdit={canEdit}
                        handleChange={onChangeForm}
                    />
                ),
            },
            {
                menuItem: 'articleParameters',
                render: () => (
                    <Parameters
                        form={form}
                        onChange={onChangeForm}
                        gridName={name}
                        load={loading}
                        error={error}
                    />
                )
            },
            {
                menuItem: 'articlePalletTypes',
                render: () => (
                    <PalletTypes
                        form={form}
                        onChange={onChangeForm}
                        gridName={name}
                        load={loading}
                        error={error}
                    />
                )
            }
        ];

        let groups = Array.from(new Set(columns.filter(column => column.groupKey).map((column) => column.groupKey)));
        groups.forEach(groupKey => {
            let groupColumns = columns.filter(column => column.groupKey === groupKey);
            obj.push({
                menuItem: groupKey,
                render: () => (
                    <Content 
                        columns={groupColumns} 
                        error={error} 
                        form={form} 
                        canEdit={canEdit}
                        handleChange={onChangeForm}
                    />
                ),
            });
        });

        if (useDocuments) {
            obj.push({
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        cardId={id}
                        isEditPermissions={canEdit}
                    />
                ),
            });
        }

        if (useHistory) {
            obj.push({
                menuItem: 'history',
                render: () => <History cardId={id} />,
            });
        }

        return obj;
    };

    return (
        <CardLayout
            title={title}
            actionsFooter={actionsFooter}
            actionsHeader={actionsHeader}
            content={getPanes}
            onClose={onClose}
            loading={loading}
        />

    );
};

export default ArticleTabs;
