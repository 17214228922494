import React from 'react';
import {useSelector} from 'react-redux';
import TableInfo from '../../../../components/TableInfo';
import { isCustomPageSelector } from '../../../../ducks/profile';

const CostChangesHistory = props => {
    const { form } = props;
    
    const customPage = useSelector(state => isCustomPageSelector(state));

    return (
        <div className="tabs-card_table">
            <TableInfo 
                key="costHistory"
                name="costHistory"
                className='compact-container'
                headerRow={customPage.shippingCostHistoryColumns}
                list={form && form.costHistory || []}
                isSubTable={true}
                canEdit={false}
            />
        </div>
    );
};

export default CostChangesHistory;
