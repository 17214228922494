import {isEmpty} from "lodash";

export const columnIsVisible = (column, form, clearInvisible, onChange) => {
    const visibilityCheckStatus = column.visibilityCheck && Object.keys(column.visibilityCheck).some(v => {
        const valueFromCheck = form[v];
        return valueFromCheck && valueFromCheck[column.visibilityCheck[v]];
    });

    const isVisible = isEmpty(column.visibilityCheck) || visibilityCheckStatus;

    // очистка значения поля, если оно не отображается
    if (!isVisible && clearInvisible && !!form[column.name] && onChange) {
        onChange(null, {
            name: column.name,
            value: null
        })
    }

    return isVisible;
}
