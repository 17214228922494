import React from 'react';
import {Button, Icon, Label, Table} from 'semantic-ui-react';

const ScenarioTableHeader = ({ statusList, t }) => {
    return (
        <>
            <Table.Row>
                <Table.HeaderCell className="field-settings-table_fixed field-settings-table_fixed_header" rowSpan="2">{t('field')}</Table.HeaderCell>
                <Table.HeaderCell colSpan={statusList.length} textAlign="center">
                    {t('status')}
                </Table.HeaderCell>
            </Table.Row>
            <Table.Row className="ext-header-row">
                {statusList.map(status => (
                    <Table.HeaderCell key={status.value} textAlign="center">
                        <Icon name="circle" color={status.color}/>
                        {t(status.name)}
                    </Table.HeaderCell>
                ))}
            </Table.Row>
        </>
    );
};

export default React.memo(ScenarioTableHeader);
