import React, { useRef, useEffect } from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ContentEditable from 'react-contenteditable';

const BigText = ({
    value,
    name,
    onChange,
    isDisabled,
    hintKey,
    noLabel,
    className,
    rows,
    text,
    isRequired,
    error,
    autoFocus,
    isGrid,
    formRef,
}) => {
    const { t } = useTranslation();
    const ref = useRef(null);

    useEffect(() => {
        if (ref && ref.current) {
            ref && ref.current && ref.current.focus && ref.current.focus();

            const paste = event => {
                event.preventDefault();
                document.execCommand('inserttext', false, event.clipboardData.getData('text/plain'));
            };

            document.querySelector('[contenteditable]').addEventListener('paste', paste);

            return () => {
                document.querySelector('[contenteditable]').removeEventListener('paste', paste);
            }
        }
    }, []);

    const getClassNames = () => {
        const classNames = [];

        if (error) {
            classNames.push('input-error');
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(' ');
    };

    const handleKeyPress = e => {

        if (e.key === 'Enter' && e.ctrlKey && isGrid) {
            e.preventDefault();
            formRef &&
                formRef.current &&
                formRef.current.handleSubmit &&
                formRef.current.handleSubmit();
        }
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            {isGrid ? (
                <ContentEditable
                    innerRef={ref}
                    className="textarea"
                    html={value} // innerHTML of the editable div
                    disabled={isDisabled} // use true to disable editing
                    onChange={e => {
                        onChange(e, { name, value: e.target.value.replace(/<\/?[^>]+(>|$)/g, "") })
                    }} // handle innerHTML change
                    tagName="span" // Use a custom HTML tag (uses a div by default)
                    onKeyUp={handleKeyPress}
                />
            ) : (
                <TextArea
                    className={getClassNames()}
                    autoHeight
                    disabled={isDisabled || false}
                    name={name}
                    rows={isGrid ? 1 : rows}
                    value={value || ''}
                    autoFocus={autoFocus}
                    transparent={isGrid}
                    onChange={onChange}
                />
            )}
            {hintKey ? (
                <span className="label-hint">{t(hintKey)}</span>
            ) : null}
            {hintKey && error && typeof error === 'string' && <br/>}
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default BigText;
