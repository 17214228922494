import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {columnsCardTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import {Divider, Form, Grid, Icon} from "semantic-ui-react";
import {clearError} from "../../../../ducks/gridCard";
import AdditionalCost from './additionalCost';

const AdditionalCosts = ({form = {}, onChange, settings, error}) => {
    const entries = form.additionalCosts && form.additionalCosts.length ? form.additionalCosts : [{}];

    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, SHIPPINGS_GRID));

    const dispatch = useDispatch();

    const handleChange = (e, {name, value}, index) => {
        const newData = [...entries];
        newData[index] = {
            ...newData[index],
            [name]: value
        };
        if (name === 'costConditionId') {
            newData[index] = {
                ...newData[index],
                ['costConditionCode']: value && value.code
            };
        }
        onChange(null, {name: 'additionalCosts', value: newData});
        dispatch(clearError(`${name}_${index}`));
    }

    const handleDelete = (index) => {
        const newData = [...entries];
        newData.splice(index, 1);
        onChange(null, {name: 'additionalCosts', value: newData});
    }

    const handleCreate = () => {
        const newData = [...entries, {}];
        onChange(null, {name: 'additionalCosts', value: newData});
    }

    return (
        <>
            {entries.map((entry, index) => (<Form key={'return'+index} className="tabs-card return__form">
                <div className='return__delete-btn'>
                    <Icon name='delete' onClick={() => handleDelete(index)}/>
                </div>
                <Grid className='return__grid'>
                    <AdditionalCost
                        index={index}
                        form={entry}
                        error={error}
                        onChange={(e, {name, value}) => handleChange(e, {name, value}, index)}
                        columnsConfig={columnsConfig}
                        settings={settings}
                    />
                </Grid>
            </Form>))}
            <div className='return__add-btn'>
                <Divider horizontal><Icon name='plus' onClick={handleCreate}/></Divider>
            </div>
        </>
    );
};

export default AdditionalCosts;
