import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import { ACTIVE_TYPE} from '../../../constants/columnTypes';
import TableCell from "./table_cell";
import CellValue from "../../../components/ColumnsValue";
import {hi} from "date-fns/locale";

const TableBody = ({
    setting,
    onChangeSettings
}) => {
    let [hidden, setHidden] = useState(setting.isHidden);
    let [required, setRequired] = useState(setting.isRequired);
    let [displayName, setDisplayName] = useState(setting.name);

    useEffect(
        () => {
            if (required !== setting.isRequired) {
                setting.isRequired = required;
                onChangeSettings(setting);
            }
            if (hidden !== setting.isHidden) {
                setting.isHidden = hidden;
                onChangeSettings(setting);
            }
        },
        [required, hidden],
    );

    const handleEditName = (value) => {
        setDisplayName(value);
        if (value !== setting.name) {
            setting.name = value;
            onChangeSettings(setting);
        }
    }

    return (
        <Table.Row>
            <Table.Cell>
                <TableCell
                    value={displayName}
                    onChanged = {handleEditName}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={required}
                    type={ACTIVE_TYPE}
                    toggleIsActive={() => setRequired(!required)}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={hidden}
                    type={ACTIVE_TYPE}
                    toggleIsActive={() => setHidden(!hidden)}
                />
            </Table.Cell>
        </Table.Row>
    );
};

export default React.memo(TableBody);
