import { createSelector } from 'reselect';
import { postman } from '../utils/postman';
import { all, put, takeEvery } from 'redux-saga/effects';
import {ORDERS_GRID, SHIPPINGS_GRID} from '../constants/grids';
import { toast } from 'react-toastify';

const TYPE_API = 'fieldProperties';

//*  TYPES  *//

const GET_FIELDS_SETTINGS_COMPANIES_REQUEST = 'GET_FIELDS_SETTINGS_COMPANIES_REQUEST';
const GET_FIELDS_SETTINGS_COMPANIES_SUCCESS = 'GET_FIELDS_SETTINGS_COMPANIES_SUCCESS';
const GET_FIELDS_SETTINGS_COMPANIES_ERROR = 'GET_FIELDS_SETTINGS_COMPANIES_ERROR';

const GET_FIELDS_SETTINGS_REQUEST = 'GET_FIELDS_SETTINGS_REQUEST';
const GET_FIELDS_SETTINGS_SUCCESS = 'GET_FIELDS_SETTINGS_SUCCESS';
const GET_FIELDS_SETTINGS_ERROR = 'GET_FIELDS_SETTINGS_ERROR';

const EDIT_FIELDS_SETTINGS_REQUEST = 'EDIT_FIELDS_SETTINGS_REQUEST';
const EDIT_FIELDS_SETTINGS_SUCCESS = 'EDIT_FIELDS_SETTINGS_SUCCESS';
const EDIT_FIELDS_SETTINGS_ERROR = 'EDIT_FIELDS_SETTINGS_ERROR';

const EDIT_FIELDS_NAME_REQUEST = 'EDIT_FIELDS_NAME_REQUEST';
const EDIT_FIELDS_NAME_SUCCESS = 'EDIT_FIELDS_NAME_SUCCESS';
const EDIT_FIELDS_NAME_ERROR = 'EDIT_FIELDS_NAME_ERROR';

const EDIT_FIELDS_MAX_LENGTH_REQUEST = 'EDIT_FIELDS_MAX_LENGTH_REQUEST';
const EDIT_FIELDS_MAX_LENGTH_SUCCESS = 'EDIT_FIELDS_MAX_LENGTH_SUCCESS';
const EDIT_FIELDS_MAX_LENGTH_ERROR = 'EDIT_FIELDS_MAX_LENGTH_ERROR';

const TOGGLE_HIDDEN_STATE_REQUEST = 'TOGGLE_HIDDEN_STATE_REQUEST';
const TOGGLE_HIDDEN_STATE_SUCCESS = 'TOGGLE_HIDDEN_STATE_SUCCESS';
const TOGGLE_HIDDEN_STATE_ERROR = 'TOGGLE_HIDDEN_STATE_ERROR';

const GET_SCENARIO_SETTINGS_REQUEST = 'GET_SCENARIO_SETTINGS_REQUEST';
const GET_SCENARIO_SETTINGS_SUCCESS = 'GET_SCENARIO_SETTINGS_SUCCESS';
const GET_SCENARIO_SETTINGS_ERROR = 'GET_SCENARIO_SETTINGS_ERROR';

const GET_SCENARIO_SETTINGS_COMPANIES_REQUEST = 'GET_SCENARIO_SETTINGS_COMPANIES_REQUEST';
const GET_SCENARIO_SETTINGS_COMPANIES_SUCCESS = 'GET_SCENARIO_SETTINGS_COMPANIES_SUCCESS';
const GET_SCENARIO_SETTINGS_COMPANIES_ERROR = 'GET_SCENARIO_SETTINGS_COMPANIES_ERROR';


const EDIT_SCENARIO_SETTINGS_REQUEST = 'EDIT_SCENARIO_SETTINGS_REQUEST';
const EDIT_SCENARIO_SETTINGS_SUCCESS = 'EDIT_SCENARIO_SETTINGS_SUCCESS';
const EDIT_SCENARIO_SETTINGS_ERROR = 'EDIT_SCENARIO_SETTINGS_ERROR';

const EDIT_ENTITY_NAME_REQUEST = 'EDIT_ENTITY_NAME_REQUEST';
const EDIT_ENTITY_NAME_SUCCESS = 'EDIT_ENTITY_NAME_SUCCESS';
const EDIT_ENTITY_NAME_ERROR = 'EDIT_ENTITY_NAME_ERROR';

const CLEAR_FIELDS_SETTINGS = 'CLEAR_FIELDS_SETTINGS';

const DEFAULT_STATE = 'DEFAULT_STATE';

//*  INITIAL STATE  *//

const initial = {
    settings: {},
    scenarioSettings: {},
    progress: false,
    editProgress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload = {} }) => {
    switch (type) {
        case GET_FIELDS_SETTINGS_COMPANIES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_FIELDS_SETTINGS_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: payload,
                progress: false,
            };
        case GET_FIELDS_SETTINGS_COMPANIES_ERROR:
            return {
                ...state,
                companies: {},
                progress: false,
            };
        case GET_FIELDS_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_FIELDS_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: payload,
                progress: false,
            };
        case EDIT_FIELDS_SETTINGS_REQUEST:
            const { params = {} } = payload;

            return {
                ...state,
                editProgress: {
                    field: params.fieldName,
                    state: params.state,
                },
            };
        case EDIT_FIELDS_SETTINGS_SUCCESS:
        case EDIT_FIELDS_SETTINGS_ERROR:
            return {
                ...state,
                editProgress: false,
            };
        case EDIT_SCENARIO_SETTINGS_REQUEST:
            
            return {
                ...state,
                editProgress: {
                    field: payload.params.fieldName,
                    state: payload.params.state,
                },
            };
        case EDIT_SCENARIO_SETTINGS_SUCCESS:
        case EDIT_SCENARIO_SETTINGS_ERROR:
            return {
                ...state,
                editProgress: false,
            };
        case EDIT_FIELDS_NAME_REQUEST:
            return {
                ...state,
                settings: payload,
                editProgress: false,
            };
        case EDIT_FIELDS_NAME_SUCCESS:
        case EDIT_FIELDS_NAME_ERROR:
            return {
                ...state,
                editProgress: false,
            };
        case EDIT_FIELDS_MAX_LENGTH_REQUEST:
            return {
                ...state,
                settings: payload,
                editProgress: false,
            };
        case EDIT_FIELDS_MAX_LENGTH_SUCCESS:
        case EDIT_FIELDS_MAX_LENGTH_ERROR:
            return {
                ...state,
                editProgress: false,
            };
        case GET_FIELDS_SETTINGS_ERROR:
            return {
                ...state,
                settings: {},
                progress: false,
            };
        case GET_SCENARIO_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SCENARIO_SETTINGS_SUCCESS:
            return {
                ...state,
                scenarioSettings: payload,
                progress: false,
            };
        case GET_SCENARIO_SETTINGS_ERROR:
            return {
                ...state,
                scenarioSettings: {},
                progress: false,
            };
        case GET_SCENARIO_SETTINGS_COMPANIES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SCENARIO_SETTINGS_COMPANIES_SUCCESS:
            return {
                ...state,
                scenarioCompanies: payload,
                progress: false,
            };
        case GET_SCENARIO_SETTINGS_COMPANIES_ERROR:
            return {
                ...state,
                scenarioCompanies: {},
                progress: false,
            };
        case EDIT_ENTITY_NAME_REQUEST:
            return {
                ...state,
                editProgress: false,
            };
        case EDIT_ENTITY_NAME_SUCCESS:
        case EDIT_ENTITY_NAME_ERROR:
            return {
                ...state,
                editProgress: false,
            };
        case CLEAR_FIELDS_SETTINGS:
            return {
                ...state,
                ...initial,
            };
        case DEFAULT_STATE:
            return {
                ...initial
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getFieldsSettingCompaniesRequest = payload => {
    return {
        type: GET_FIELDS_SETTINGS_COMPANIES_REQUEST,
        payload,
    };
};

export const getFieldsSettingRequest = payload => {
    return {
        type: GET_FIELDS_SETTINGS_REQUEST,
        payload,
    };
};

export const getScenarioSettingRequest = payload => {
    return {
        type: GET_SCENARIO_SETTINGS_REQUEST,
        payload,
    };
};

export const getScenarioSettingCompaniesRequest = payload => {
    return {
        type: GET_SCENARIO_SETTINGS_COMPANIES_REQUEST,
        payload,
    };
};

export const editFieldsSettingRequest = payload => {
    return {
        type: EDIT_FIELDS_SETTINGS_REQUEST,
        payload,
    };
};

export const editScenarioSettingRequest = payload => {
    return {
        type: EDIT_SCENARIO_SETTINGS_REQUEST,
        payload,
    };
};

export const editFieldsNameRequest = payload => {
    return {
        type: EDIT_FIELDS_NAME_REQUEST,
        payload,
    };
};

export const clearFieldsSettings = () => {
    return {
        type: CLEAR_FIELDS_SETTINGS,
    };
};

export const toggleHidenStateRequest = payload => {
    return {
        type: TOGGLE_HIDDEN_STATE_REQUEST,
        payload,
    };
};

export const editFieldsMaxLengthRequest = payload => {
    return {
        type: EDIT_FIELDS_MAX_LENGTH_REQUEST,
        payload,
    };
};

export const editEntityNameRequest = payload => {
    return {
        type: EDIT_ENTITY_NAME_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.fieldsSetting;

export const fieldsSettingCompaniesSelector = createSelector(stateSelector, state => state.companies);

export const fieldsSettingSelector = createSelector(stateSelector, state => state.settings);

export const scenarioSettingSelector = createSelector(stateSelector, state => state.scenarioSettings);

export const scenarioSettingCompaniesSelector = createSelector(stateSelector, state => state.scenarioCompanies);

export const progressSelector = createSelector(stateSelector, state => state.progress);

export const editProgressSelector = createSelector(stateSelector, state => state.editProgress);

//*  SAGA  *//
export function* getFieldsSettingCompaniesSaga({ payload }) {
    try {
        const result = yield postman.get(`${TYPE_API}/companies`);
        yield put({
            type: GET_FIELDS_SETTINGS_COMPANIES_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_FIELDS_SETTINGS_COMPANIES_ERROR,
            payload: e,
        });
    }
}

export function* getFieldsSettingSaga({ payload }) {
    try {
        const baseResult = yield postman.post(`${TYPE_API}/get`, payload);
        let forEntity = '';
        let extResult = [];
        if (payload.forEntity === ORDERS_GRID) {
            forEntity = 'orderItems'
        } else if (payload.forEntity === SHIPPINGS_GRID) {
            forEntity = 'routePoints'
        }
         if (forEntity) {
            if (payload.forEntity === ORDERS_GRID) {
                extResult['orderItems'] = yield postman.post(`${TYPE_API}/get`, { ...payload, forEntity: 'orderItems' });
            } else {
                extResult['loadingRoutePoints'] = yield postman.post(`${TYPE_API}/get`, { ...payload, forEntity: 'loadingRoutePoints' });
                extResult['unloadingRoutePoints'] = yield postman.post(`${TYPE_API}/get`, { ...payload, forEntity: 'unloadingRoutePoints' });
            }
         }

        yield put({
            type: GET_FIELDS_SETTINGS_SUCCESS,
            payload: {
                base: baseResult,
                ext: extResult,
            },
        });
    } catch (e) {
        yield put({
            type: GET_FIELDS_SETTINGS_ERROR,
            payload: e,
        });
    }
}

export function* getScenarioSettingSaga({ payload }) {
    try {
        const baseResult = yield postman.post(`scenarioSettings/get`, payload);
        yield put({
            type: GET_SCENARIO_SETTINGS_SUCCESS,
            payload: {
                base: baseResult,
            },
        });
    } catch (e) {
        yield put({
            type: GET_SCENARIO_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* editScenarioSettingSaga({ payload = {} }) {
    try {
        const { params, callbackSuccess, extType } = payload;
        const result = yield postman.post(`scenarioSettings/save`, {
            ...params,
            forEntity: extType || params.forEntity,
        });

        yield put({
            type: EDIT_SCENARIO_SETTINGS_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_SCENARIO_SETTINGS_ERROR,
            payload: e,
        });
    }
}

export function* getScenarioSettingCompaniesSaga({ payload }) {
    try {
        const result = yield postman.get(`scenarioSettings/companies`);
        yield put({
            type: GET_SCENARIO_SETTINGS_COMPANIES_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_SCENARIO_SETTINGS_COMPANIES_ERROR,
            payload: e,
        });
    }
}

function* editFieldsSettingSaga({ payload = {} }) {
    try {
        const { params, callbackSuccess, extType } = payload;
        const result = yield postman.post(`/${TYPE_API}/save`, {
            ...params,
            forEntity: extType || params.forEntity,
        });

        yield put({
            type: EDIT_FIELDS_SETTINGS_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_FIELDS_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* editFieldsNameSaga({ payload = {} }) {
    try {
        const { params, callbackSuccess, extType } = payload;
        const result = yield postman.post(`/${TYPE_API}/changeName`, {
            ...params,
            forEntity: extType || params.forEntity,
        });

        yield put({
            type: EDIT_FIELDS_NAME_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_FIELDS_NAME_ERROR,
            payload: e,
        });
    }
}

function* toggleHiddenStateSaga({ payload }) {
    try {
        const { params, callbackSuccess, extType } = payload;
        const result = yield postman.post(`/${TYPE_API}/toggleSetting/${params.settingName}`, {
            ...params,
            forEntity: extType || params.forEntity,
        });

        yield put({
            type: TOGGLE_HIDDEN_STATE_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: TOGGLE_HIDDEN_STATE_ERROR,
            payload: e,
        });
    }
}

function* editFieldsMaxLengthSaga({ payload = {} }) {
    try {
        const { params, callbackSuccess, extType } = payload;
        const result = yield postman.post(`/${TYPE_API}/changeMaxLength`, {
            ...params,
            forEntity: extType || params.forEntity,
        });
        
        if (result.isError) {
            result.message && toast.error(result.message);
            yield put({
                type: EDIT_FIELDS_MAX_LENGTH_ERROR,
                payload: result.message,
            });
        } else {
            yield put({
                type: EDIT_FIELDS_MAX_LENGTH_SUCCESS,
            });
        }
    
        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_FIELDS_MAX_LENGTH_ERROR,
            payload: e,
        });
    }
}

function* editEntityNameSaga({ payload = {} }) {
    try {
        const { params, callbackSuccess } = payload;
        yield postman.post(`/${TYPE_API}/changeEntityName`, params);

        yield put({
            type: EDIT_ENTITY_NAME_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_ENTITY_NAME_ERROR,
            payload: e,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_FIELDS_SETTINGS_COMPANIES_REQUEST, getFieldsSettingCompaniesSaga),
        takeEvery(GET_FIELDS_SETTINGS_REQUEST, getFieldsSettingSaga),
        takeEvery(EDIT_FIELDS_SETTINGS_REQUEST, editFieldsSettingSaga),
        takeEvery(EDIT_FIELDS_NAME_REQUEST, editFieldsNameSaga),
        takeEvery(TOGGLE_HIDDEN_STATE_REQUEST, toggleHiddenStateSaga),
        takeEvery(EDIT_FIELDS_MAX_LENGTH_REQUEST, editFieldsMaxLengthSaga),
        takeEvery(GET_SCENARIO_SETTINGS_REQUEST, getScenarioSettingSaga),
        takeEvery(EDIT_SCENARIO_SETTINGS_REQUEST, editScenarioSettingSaga),
        takeEvery(GET_SCENARIO_SETTINGS_COMPANIES_REQUEST, getScenarioSettingCompaniesSaga),
        takeEvery(EDIT_ENTITY_NAME_REQUEST, editEntityNameSaga),
    ]);
}
