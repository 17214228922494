import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import FormField from '../formField';
import { columnsCardTypesConfigSelector } from '../../../../ducks/representations';
import { ORDERS_GRID } from '../../../../constants/grids';

const Information = ({
    form,
    onChange,
    uniquenessNumberCheck,
    settings,
    error,
}) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector((state) => columnsCardTypesConfigSelector(state, ORDERS_GRID));

    const handleChangeShippingWarehouseId = useCallback((e, { name, value }) => {
        onChange(e, {
            name,
            value,
        });
        onChange(e, { name: 'shippingAddress', value: value && value.address ? value.address : '' });
        onChange(e, { name: 'shippingCity', value: value && value.city ? value.city : '' });
        onChange(e, { name: 'shippingRegion', value: value && value.region ? value.region : '' });
    }, []);

    const handleChangeDeliveryWarehouseId = useCallback((e, { name, value }) => {

        onChange(e, {
            name,
            value,
        });
        onChange(e, { name: 'clientId', value: value && value.client ? value.client : '' });
        onChange(e, { name: 'deliveryAddress', value: value && value.address ? value.address : '' });
        onChange(e, { name: 'deliveryCity', value: value && value.city ? value.city : '' });
        onChange(e, { name: 'deliveryRegion', value: value && value.region ? value.region : '' });
    }, []);

    const renderField = (name, columnWidth, params = {}) => {
        if (settings && settings[name] === "hidden") {
            return null;
        }
        return (
            <Grid.Column width={columnWidth}>
                {
                    React.cloneElement(
                        <FormField
                            name={name}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />, 
                        { ...params }
                    )}
            </Grid.Column>
        );
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('general info')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row className="field-row">
                                        {!form.id && renderField("orderNumber", 4, { isRequired: true, isReadOnly: false, onBlur: uniquenessNumberCheck })}
                                        {renderField("invoiceNumber", 4)}
                                        {renderField("clientOrderNumber", 4)}
                                        {renderField("shipmentConditionId", 4)}
                                        {renderField("commodityCode", 4)}
                                        {renderField("groupNumber", 4)}
                                        {renderField("schedulePlanId", 4)}
                                        {renderField("shipmentType", 4)}
                                        {renderField("inboundCode", 4)}
                                        {renderField("orderDeliveryType", 4)}
                                        {form.id && renderField("shippingNumber", 4)}
                                        {form.id && renderField("orderShippingStatus", 4)}
                                        {renderField("clientType", 4)}
                                        {renderField("bodyTypeId", 4)}
                                        {renderField("deliveryKind", 4)}
                                        {renderField("paymentCondition", 4)}
                                        {renderField("payer", 4)}
                                        {form.id && renderField("pickingType", 4)}
                                        {form.id && renderField("orderType", 4, { isTranslate: true })}
                                        {renderField("cargoType", 4, { isTranslate: true })}
                                        {renderField("orderDate", 4)}
                                        {renderField("clientId", 4)}
                                        {renderField("temperatureMin", 4)}
                                        {renderField("temperatureMax", 4)}
                                        {form.id && renderField("errorsType", 4)}
                                        {form.id && renderField("errors", 4)}
                                        {renderField("commodityTemperatureConditions", 4)}
                                        {form.id && renderField("shipmentCategory", 4)}
                                        {!form.id && renderField("shipmentCategory", 4, { isReadOnly: false })}
                                        {form.id && renderField("logisticsGroupCode", 4)}
                                        {!form.id && renderField("logisticsGroupCode", 4, { isReadOnly: false })}
                                        {renderField("isExport", 4)}
                                        {renderField("isShuttling", 4)}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('route')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <Grid>
                                                <Grid.Row className="field-row">
                                                    {renderField("shippingCountry", 8)}
                                                    {renderField("shippingRegion", 8)}
                                                    {renderField("shippingCity", 8)}
                                                    {renderField("shippingWarehouseId", 8, { onChange: handleChangeShippingWarehouseId })}
                                                    {form.id && renderField("shippingTransportZoneId", 8)}
                                                    {renderField("shippingWarehouseCode", 8)}
                                                    {renderField("shippingAddress", 16)}
                                                    {renderField("shippingDate", 8, { maxDate: form.deliveryDate })}
                                                    <Grid.Column width={8}></Grid.Column>
                                                    {renderField("shippingTimeFrom", 8)}
                                                    {renderField("shippingTimeTo", 8)}
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Grid>
                                                <Grid.Row className="field-row">
                                                    {renderField("deliveryCountry", 8)}
                                                    {renderField("deliveryRegion", 8)}
                                                    {renderField("deliveryCity", 8)}
                                                    {renderField("deliveryWarehouseId", 8, { onChange: handleChangeDeliveryWarehouseId })}
                                                    {form.id && renderField("deliveryTransportZoneId", 8)}
                                                    {renderField("deliveryWarehouseCode", 8)}
                                                    {renderField("deliveryAddress", 16)}
                                                    {renderField("deliveryDate", 8, { minDate: form.shippingDate })}
                                                    <Grid.Column width={8}></Grid.Column>
                                                    {renderField("deliveryTimeFrom", 8)}
                                                    {renderField("deliveryTimeTo", 8)}
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row className="field-row">
                                        {renderField("trucksDowntime", 4)}
                                        {form.id && renderField("zonesRoute", 4)}
                                        {form.id && renderField("citiesRoute", 4)}
                                        {renderField("routeDistance", 4)}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('cargo_characteristics')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row className="field-row">
                                        {renderField("weightNetKg", 4)}
                                        {renderField("weightGrossKg", 4)}
                                        {renderField("actualWeightKg", 4)}
                                        {renderField("volume", 4)}
                                        {renderField("volume9l", 4)}
                                        {renderField("productTypeId", 4)}
                                        {renderField("productKind", 4)}
                                        {form.id && renderField("productCategory", 4)}
                                        {!form.id && renderField("productCategory", 4, { isReadOnly: false })}
                                        {renderField("temperatureConditions", 4)}
                                        {renderField("palletsWithTypes", 4)}
                                        {renderField("palletsCount", 4)}
                                        {renderField("actualPalletsCount", 4)}
                                        {renderField("boxesCount", 4)}
                                        {renderField("unitsCount", 4)}
                                        {renderField("isAlcoProduct", 4)}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Information;
