import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Segment } from 'semantic-ui-react';
import FormField from '../formField';
import {useSelector} from "react-redux";
import {columnsCardTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";

const Information = ({ form = {}, onChange, settings, error }) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, SHIPPINGS_GRID));

    const renderField = (name, columnWidth, params = {}) => {
        if (settings && settings[name] === "hidden") {
            return null;
        }
        return (
            <Grid.Column width={columnWidth}>
                {
                    React.cloneElement(
                        <FormField
                            name={name}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />, 
                        { ...params }
                    )}
            </Grid.Column>
        );
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('general info')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row className="field-row">
                                        {renderField("shippingNumber", 4)}
                                        {renderField("deliveryType", 4, { isTranslate: true })}
                                        {renderField("shipmentType", 4)}
                                        {renderField("inboundCode", 4)}
                                        {renderField("sapContractNumber", 4)}
                                        {renderField("contractNumber", 4)}
                                        {renderField("contractPeriod", 4)}
                                        {renderField("contractSignDate", 4)}
                                        {renderField("unitedShippingNumber", 4)}
                                        {renderField("carrierId", 4)}
                                        {renderField("shortRoute", 4)}
                                        {renderField("fullRoute", 4)}
                                        {renderField("routeDistance", 4)}
                                        {renderField("errorsType", 4, { isTranslate: true })}
                                        {renderField("errors", 4)}
                                        {renderField("errorsComment", 4)}
                                        {renderField("isAuction", 4)}
                                        {renderField("isAllocated", 4)}
                                        {renderField("carrierAssignmentType", 4)}
                                        {renderField("deviationReasonsComments", 4)}
                                        {renderField("carrierConfirmationMinutes", 4)}
                                        {renderField("carrierAssetsFillingMinutes", 4)}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('cargo_vehicle_requirements')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row className="field-row">
                                        {renderField("vehicleTypeId", 4)}
                                        {renderField("weightGrossKg", 4)}
                                        {renderField("weightKg", 4)}
                                        {renderField("actualWeightKg", 4)}
                                        {renderField("productTypeIds", 4)}
                                        {renderField("productKind", 4)}
                                        {renderField("commodityCodes", 4)}
                                        {renderField("poolingProductType", 4)}
                                        {renderField("volume", 4)}
                                        {renderField("palletsCount", 4)}
                                        {renderField("actualPalletsCount", 4)}
                                        {renderField("temperatureMin", 4)}
                                        {renderField("temperatureMax", 4)}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('driver_vehicle_trailer')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row className="field-row">
                                        {renderField("driverId", 4)}
                                        {renderField("driverPassportData", 4)}
                                        {renderField("driverLicence", 4)}
                                        {renderField("driverLicenceDate", 4)}
                                        {renderField("vehicleId", 4)}
                                        {renderField("trailerId", 4)}
                                        {renderField("powerOfAttorneyNumber", 4)}
                                        {renderField("dateOfPowerOfAttorney", 4)}
                                        {renderField("vehicleCarrierName", 4)}
                                        {renderField("vehicleCarrierInn", 4)}
                                        {renderField("vehicleOwnershipTypeId", 4)}
                                        {renderField("vehicleCarrierOwnershipTypeId", 4)}
                                        {renderField("finalCarrierName", 4)}
                                        {renderField("finalCarrierInn", 4)}
                                        {renderField("carrierPhone", 4)}
                                        {renderField("carrierAddress", 4)}
                                        {renderField("carrierContractType", 4)}
                                        {renderField("carrierContractNumber", 4)}
                                        {renderField("carrierContractDate", 4)}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Information;
