import React from "react";
import { useSelector } from "react-redux";
import TableInfo from "../../../../components/TableInfo";
import { isCustomPageSelector } from "../../../../ducks/profile";

const OrderAddingToTransportationsHistory = props => {
    const { form } = props;
    
    const customPage = useSelector(state => isCustomPageSelector(state));
    
    return (
        <div className="tabs-card_table">
            <TableInfo
                key="orderAddingToTransportationsHistory"
                className='compact-container'
                headerRow={customPage.orderAddingToTransportationsHistoryColumns}
                list={form.orderAddingToTransportationsHistory}
                isSubTable={true}
                canEdit={false}
            />
        </div>
    );
};

export default OrderAddingToTransportationsHistory;