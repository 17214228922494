import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Form, Icon, Popup } from 'semantic-ui-react';

import './style.scss';
import {getLookupRequest, listFromMultiSelect } from '../../ducks/lookup';
import { PAGE_SIZE } from '../../constants/settings';
import { toast } from 'react-toastify';

const MultiSelect = ({
    value,
    onChange,
    placeholder = '',
    isDisabled,
    hintKey,
    name,
    text,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    isPartialSelect,
    autoComplete,
    children,
    sourceParams,
    upward
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let timer = useRef(null);
    
    let [open, setOpen] = useState(false);
    let [searchQuery, setSearchQuery] = useState('');
    let [filter, setFilter] = useState('');

    const getLookup = () => {
        let requestSourceParams = isPartialSelect
            ? {
                ...sourceParams,
                search: filter,
                limit: PAGE_SIZE
            }
            : sourceParams;
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams: requestSourceParams,
            }),
        );
    }

    useEffect(() => {
        isPartialSelect && open && getLookup();
    }, [filter]);

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setFilter(searchQuery && searchQuery.trim());
        }, 300);
    }, [searchQuery]);

    const valuesList = useSelector(state =>
        listFromMultiSelect(state, `${source}${(sourceParams && sourceParams.orderId) || ''}`, isTranslate, t)
    );

    const handleChange = (e, { value, options }) => {
        onChange(e, { value: value ? options.filter(item => value.includes(item.value)) : null, name });
    };

    const handleOpen = () => {
        setOpen(true);
        getLookup();
    };

    const handleClose = () => {
        setOpen(false);
        setSearchQuery('');
    };

    const handleSearch = (options, query) => {
        if (isPartialSelect) {
            return options;
        } else {
            return options.filter(item => item && item.text && query && item.text.toLowerCase().replaceAll(' ', '').includes(query.toLowerCase().replaceAll(' ', '')));
        }
    };

    const handleSearchChange = (e, { searchQuery }) => {
        setSearchQuery(searchQuery);
    };

    const getAllOptions = () => {
        let result = valuesList || [];
        var ids = result.map(item => item.value);
        if (value) {
            return result.concat(
                value.filter(item => !ids.includes(item.value))
                    .map(item => {
                        return {
                            ...item,
                            text: t(item.name)
                        };
                    })
            );
        } else {
            return result;
        }
    };

    const copyToClipboard = () => {
        const text = value ? value.map(item => item.name).join(', ') : null;
        text && 
            navigator.clipboard &&
            navigator.clipboard.writeText(text).then(
                () => {
                    toast.info(t('copied_to_clipboard_success'));
                },
                error => {
                    toast.error(t('copied_to_clipboard_error', { error }));
                },
            );
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    {`${t(text || name)}${isRequired ? ' *' : ''}`}
                    <Popup 
                        content={t('copy_value')} 
                        trigger={
                            <div className="field-attr">
                                <div className="cell-grid-copy-btn">
                                    <Icon
                                        name="clone outline"
                                        size="small"
                                        onClick={copyToClipboard}
                                    />
                                </div>
                            </div>
                        } 
                        position='bottom left' 
                    /> 
                </label>
            ) : null}
            <div className="form-select">
                <Dropdown
                    upward={upward}
                    placeholder={t(placeholder)}
                    fluid
                    deburr
                    clearable={clearable}
                    selection
                    loading={loading}
                    text={textValue}
                    error={error}
                    multiple
                    disabled={isDisabled}
                    value={value ? value.map(item => item.value) : []}
                    options={getAllOptions()}
                    onChange={handleChange}
                    selectOnBlur={false}
                    closeOnBlur={true}
                    onClose={handleClose}
                    autoComplete={autoComplete}
                    search={handleSearch}
                    onOpen={handleOpen}
                    onSearchChange={handleSearchChange}
                />
                {children && children}
            </div>
            {hintKey && <span className="label-hint">{t(hintKey)}</span>}
            {hintKey && error && typeof error === 'string' && <br/>}
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};

export default React.memo(MultiSelect);
