import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import Text from '../../../../components/BaseComponents/Text';
import { useDispatch, useSelector } from 'react-redux';
import { cardSelector, editCardRequest, settingsExtSelector } from '../../../../ducks/gridCard';
import { getLookupRequest, valuesListSelector } from '../../../../ducks/lookup';
import Number from '../../../../components/BaseComponents/Number';
import {SETTINGS_TYPE_EDIT, SETTINGS_TYPE_HIDE, SETTINGS_TYPE_SHOW} from '../../../../constants/formTypes';
import FormField from '../../../../components/BaseComponents';
import { SELECT_TYPE, NUMBER_TYPE } from '../../../../constants/columnTypes';


const EditField = ({ value, name, onChange, datalist, error = [], isDisabled }) => {
    return (
        <>
        {name === 'articleId' ? (
                <Grid.Column style={{minWidth: "130px"}}>
                    <FormField
                        name={name}
                        value={value}
                        error={error && error.includes('articleId')}
                        source={'articles'}
                        onChange={onChange}
                        key={'articleId'}
                        listboxMaxHeight={"150px"}
                        type={SELECT_TYPE}
                        noLabel={true}
                        isRequired={true}
                        upward={true}
                        compact={true}
                    />
                </Grid.Column>
            ) :  name === 'unitOfMeasurement' ? (
                <Grid.Column style={{minWidth: "100px"}}>
                    <FormField
                        name={name}
                        value={value}
                        error={error && error.includes('unitOfMeasurement')}
                        source={'unitOfMeasurement'}
                        onChange={onChange}
                        key={'unitOfMeasurement'}
                        isTranslate={true}
                        listboxMaxHeight={"150px"}
                        type={SELECT_TYPE}
                        noLabel={true}
                        isRequired={true}
                        upward={true}
                        compact={true}
                    />
                </Grid.Column>
            ) : (
                <Grid.Column>
                  <FormField
                    value={value}
                    isInteger={false}
                    name={name}
                    onChange={onChange}
                    noLabel={true}
                    error={error && error.includes(name)}
                    isRequired={true}
                    type={NUMBER_TYPE}
                    size={'mini'}
                />
                </Grid.Column>
            )}
        </>
    );
};

const Position = ({ form, onChange, gridName, load, settings: baseSettings }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let [items, setItems] = useState([...(form.items || [])]);
    let [indexEdit, setIndexEdit] = useState(null);
    let [error, setError] = useState([]);

    const card = useSelector(state => cardSelector(state));
    const articles = useSelector(state => valuesListSelector(state, 'articles')) || [];
    const settings = useSelector(state => settingsExtSelector(state, form.status, 'orderItems'));
    const columns = [];

    Object.keys(settings).forEach(key => {
        if (settings[key] !== SETTINGS_TYPE_HIDE) {
            columns.push({
                name: key,
            });
        }
    });

    useEffect(() => {
        if (!articles.length) {
            dispatch(
                getLookupRequest({
                    name: 'articles',
                    isForm: true,
                }),
            );
        }
    }, []);

    useEffect(
        () => {
            setItems(form.items || []);
        },
        [form.items],
    );

    const editPositions = positions => {
        onChange(null, {
            name: 'items',
            value: positions,
        })
    };

    const handleDeleteItem = index => {
        const newItems = items.filter((item, i) => i !== index);
        editPositions(newItems);
    };

    const handleEditItem = index => {
        setIndexEdit(index);
    };

    const handleSaveItem = () => {
        const { articleId, unitOfMeasurement } = items[indexEdit];

        if (articleId && unitOfMeasurement) {
            editPositions(items);
            setIndexEdit(null);
            setError([]);
        } else {
            if (!articleId) {
                setError(prevState => [...prevState, 'articleId']);
            } else {
                setError(
                    (prevState = []) => prevState && prevState.filter(item => item !== 'articleId'),
                );
            }

            if (!unitOfMeasurement) {
                setError(prevState => [...prevState, 'unitOfMeasurement']);
            } else {
                setError(
                    (prevState = []) => prevState && prevState.filter(item => item !== 'unitOfMeasurement'),
                );
            }
        }
    };

    const handleChangeField = (e, { name, value }) => {
        const newColumns = [...items];

        newColumns[indexEdit] = {
            ...items[indexEdit],
            [name]: value,
        };

        setItems(newColumns);
    };

    const handleCancelItem = index => {
        setItems([...form.items]);
        setIndexEdit(null);
        setError(false);
    };

    const handleAddItems = () => {
        setItems([...items, {}]);
        setIndexEdit(items.length);
    };

    return (
        <div className="tabs-card" style={{overflowX: "auto"}}>
            <Grid>
                <Grid.Row columns="equal">
                    <Grid.Column width={4}>
                        <Form>
                            <FormField
                                name="orderAmountExcludingVAT"
                                value={form['orderAmountExcludingVAT']}
                                type={NUMBER_TYPE}
                                settings={baseSettings['orderAmountExcludingVAT']}
                                onChange={onChange}
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column className="add-right-elements">
                        <Button disabled={indexEdit !== null} onClick={handleAddItems}>
                            {t('AddButton')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="padding-14">
                    <Table compact={true} size={'small'}>
                        <Table.Header>
                            <Table.Row>
                                {columns.map(column => (
                                    <Table.HeaderCell key={column.name}>
                                        {t(column.name)}
                                    </Table.HeaderCell>
                                ))}
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {items ? items.map((row, index) => (
                                <Table.Row key={row.id}>
                                    {columns.map(column => (
                                        <>
                                            {index === indexEdit ?
                                                (<Table.Cell
                                                    key={`cell_${row.id}_${column.name}_${index}`}
                                                    width={'3'}
                                                >
                                                    <EditField
                                                        value={row[column.name]}
                                                        name={column.name}
                                                        error={error}
                                                        onChange={handleChangeField}
                                                    />
                                                </Table.Cell>
                                                )
                                                :
                                                (
                                                    <Table.Cell
                                                        key={`cell_${row.id}_${column.name}_${index}`}
                                                        width={'4'}
                                                    >
                                                        {((column.name === 'unitOfMeasurement'||column.name === 'articleId') && row[column.name]) ? row[column.name].name : row[column.name]}
                                                    </Table.Cell>
                                                )}
                                        </>
                                    ))}
                                    <Table.Cell
                                        textAlign="right"
                                        className="table-action-buttons"
                                    >
                                        {index === indexEdit ? (
                                            <>
                                                <Button
                                                    icon
                                                    onClick={() => handleCancelItem(index)}
                                                >
                                                    <Icon name="undo alternate" />
                                                </Button>
                                                <Button
                                                    icon
                                                    onClick={() => handleSaveItem(index)}
                                                >
                                                    <Icon name="check" />
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleEditItem(index)}
                                                >
                                                    <Icon name="pencil alternate" />
                                                </Button>
                                                <Button
                                                    disabled={indexEdit !== null}
                                                    icon
                                                    onClick={() => handleDeleteItem(index)}
                                                >
                                                    <Icon name="trash alternate" />
                                                </Button>
                                            </>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))
                                : null}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default Position;
