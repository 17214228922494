import { all, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { postman } from '../utils/postman';
import { toast } from 'react-toastify';
import { showModal } from './modal';

//*  TYPES  *//

const DATA_UPLOADING_REQUEST = 'DATA_UPLOADING_REQUEST';
const DATA_UPLOADING_SUCCESS = 'DATA_UPLOADING_SUCCESS';
const DATA_UPLOADING_ERROR = 'DATA_UPLOADING_ERROR';

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    errors: {}
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case DATA_UPLOADING_REQUEST:
            return {
                ...state,
                progress: true,
            };

        case DATA_UPLOADING_SUCCESS:
            return {
                ...state,
                errors: {},
                progress: false,
            };

        case DATA_UPLOADING_ERROR:
            return {
                ...state,
                errors: payload,
                progress: false,
            };

        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const dataUploadingRequest = payload => {
    return {
        type: DATA_UPLOADING_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.loadingData;

export const inProgressDataSelector = createSelector(
    stateSelector,
    state => state.progress,
);

export const errorsDataSelector = createSelector(
    stateSelector,
    state => state.errors,
);

//*  SAGA  *//

function* dataUploadingSaga({ payload }) {
    try {
        const { form, typeApi, mode, routeType } = payload;

        mode && form.append('mode', mode);
        routeType && form.append('routeType', routeType);

        const result = yield postman.post(`/import/` + typeApi + `/importFromExcel`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        if (result.isError && (!result.entries || !result.entries.length)) {
            yield put({
                type: DATA_UPLOADING_ERROR,
            });
            toast.error(result.message);
        } else if (!result.isError && (!result.entries || !result.entries.length) && result.message) {
            yield put({
                type: DATA_UPLOADING_SUCCESS,
            });
            toast.info(result.message);
        } else {
            yield put({
                type: DATA_UPLOADING_SUCCESS,
            });
            yield put(showModal(result));
        }
    } catch (e) {
        yield put({
            type: DATA_UPLOADING_ERROR,
        });
    }
}

export function* saga() {

    yield all([
        takeEvery(DATA_UPLOADING_REQUEST, dataUploadingSaga),
    ]);
}
