import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react';
import _ from 'lodash';
import { sortFunc } from '../../../utils/sort';

const Header = ({
    dictionariesList,
    activeItem,
    changeActiveItem,
    rolesList,
    role,
    changeRole,
    t,
    companiesList,
    company,
    changeCompany,
    onEntityRenamed,
}) => {
    let [isEdit, setIsEdit] = useState(false);
    let [initialValue, setInitialValue] = useState('');
    let [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        dictionariesList && dictionariesList.forEach(e => {
            if (e.name === activeItem) {
                setInitialValue(e.displayName);
                setCurrentValue(e.displayName);
            }
        });
    }, [activeItem]);

    const rolesListOptions = useMemo(
        () => [
            /*{ key: 'any_role', value: 'null', text: t('any_role') },*/
            ...rolesList.map((x) => ({ key: x.name, value: x.value, text: x.name })),
        ],
        [rolesList],
    );

    const dictionaryListOptions = useMemo(
        () => {
            const list = dictionariesList.map((x) => ({ key: x.name, value: x.name, text: x.displayName }));
            return sortFunc(list, t, 'key')
        },
        [dictionariesList],
    );

    const companyListOptions = useMemo(
        () => [...companiesList.map((x) => ({ key: x.name, value: x.value, text: x.name }))],
        [companiesList],
    );

    const startEdit = () => {
        setIsEdit(true);
    };

    const acceptEdit = () => {
        if (currentValue != initialValue && onEntityRenamed) {
            onEntityRenamed(activeItem, currentValue);
        }
        setIsEdit(false);
    };

    return (
        <Menu className="field-settings-menu">
            <Menu.Item>
                <span>
                    <label>{`${t('dictionary')}: `}</label>
                    {!isEdit ?
                        (<>
                            <Dropdown
                                value={activeItem}
                                inline
                                search
                                scrolling
                                options={dictionaryListOptions}
                                onChange={changeActiveItem}
                            />
                            <Icon name="pencil" onClick={startEdit} />
                        </>)
                        : (<>
                            <Input
                                className='cell-field-name-edit'
                                value={currentValue}
                                type="text"
                                onChange={(e, {value}) => setCurrentValue(value)}
                            />
                            <Icon name="check" onClick={acceptEdit} />
                        </>)
                    }
                </span>
            </Menu.Item>
            <Menu.Item>
                <span>
                    <label>{`${t('ЮЛ')}: `}</label>
                    <Dropdown
                        value={company}
                        inline
                        options={companyListOptions}
                        onChange={changeCompany}
                    />
                </span>
            </Menu.Item>
            <Menu.Item>
                <span>
                    <label>{`${t('role')}: `}</label>
                    <Dropdown
                        value={role}
                        inline
                        options={rolesListOptions}
                        onChange={changeRole}
                    />
                </span>
            </Menu.Item>
        </Menu>
    );
};

export default React.memo(Header);
