import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {Button, Confirm, Dropdown, Modal, Popup} from 'semantic-ui-react';
import {
    addError,
    cardSelector,
    clearGridCard,
    createAndSendRequest,
    createGridCardRequest,
    editCardRequest,
    editProgressSelector,
    errorSelector,
    getCardRequest,
    getCopyCardRequest,
    isUniqueNumberRequest,
    progressSelector,
    selectCarrierRequest,
    settingsFormSelector,
} from '../../ducks/gridCard';
import {
    actionsCardSelector,
    clearActions,
    getActionsRequest,
    invokeActionRequest,
    progressActionNameSelector,
} from '../../ducks/gridActions';
import { ORDERS_GRID, SHIPPINGS_GRID } from '../../constants/grids';
import { DICTIONARY_CARD_LINK, DICTIONARY_NEW_LINK, GRID_CARD_LINK } from '../../router/links';
import { clearHistory, getHistoryRequest } from '../../ducks/history';
import { getFieldsSettingRequest } from '../../ducks/fieldsSetting';
import {roleIdSelector, userPermissionsSelector} from '../../ducks/profile';
import OrderCard from './components/orderCard';
import ShippingCard from './components/shippingCard';
import OtherCard from './components/otherCard';
import { columnsCardSelector, displayNameSelector, exportCarrierDocumentsRequest, exportHistoryToExcelRequest } from '../../ducks/gridList';
import ParamsFromActions from './components/paramsFromActions';
import DeliveryCostRequests from "./components/shippingTabs/deliveryCostRequests";
import { getDocumentsRequest } from '../../ducks/documents';

const Card = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { name, id } = params;
    const { state } = location;
    
    const { copyFromId } = (state || {});
    
    let [form, setForm] = useState({});
    
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [openDeliveryCostRequestsModal, setOpenDeliveryCostRequestsModal] = useState(false);
    let [confirmation, setConfirmation] = useState({ open: false });
    let columns = useSelector(state => columnsCardSelector(state, name));
    let displayName = useSelector(state => displayNameSelector(state, name));
    let [dependentFields, setDependentFields] = useState({});
    let [formModal, setFormModal] = useState({ open: false, columns: [] });

    let ordersDisplayName = useSelector(state => displayNameSelector(state, 'orders'));
    let shippingsDisplayName = useSelector(state => displayNameSelector(state, 'shippings'));
    const userPermissions = useSelector(state => userPermissionsSelector(state));
    
    const title = useMemo(
        () =>
            id
                ? t(`edit_entry`, {
                      name: displayName,
                      number: name === ORDERS_GRID ? form.orderNumber : form.shippingNumber,
                      status: t(form.statusName || form.status),
                  })
                : t(`new_entry`, {
                      name: displayName
                  }),
        [name, id, form],
    );

    const card = useSelector(state => cardSelector(state));
    const settings = useSelector(state => settingsFormSelector(state, card.status));
    const error = useSelector(state => errorSelector(state));
    const roleId = useSelector(state => roleIdSelector(state));

    useEffect(() => {
        dispatch(clearActions());
        loadCard();

        dispatch(
            getFieldsSettingRequest({
                forEntity: name,
                roleId,
            }),
        );

        return () => {
            dispatch(clearHistory());
            dispatch(clearGridCard());
        };
    }, []);

    useEffect(() => {
        if (notChangeForm) {
            Object.keys(form).forEach(key => {
                if (form[key] !== card[key]) {
                    setNotChangeForm(false);
                }
            });
        }
    }, [form]);

    useEffect(() => {
        let obj = {};
        columns &&
            columns.length &&
            columns
                .filter(column => column.dependencies && column.dependencies.length)
                .forEach(column => {
                    column.dependencies.forEach(item => {
                        obj = {
                            ...obj,
                            [item]: [...(obj[item] || []), column.name],
                        };
                    });
                });

        setDependentFields(obj);
    }, [columns]);
    
    const loadCard = () => {
        copyFromId && 
            dispatch(
                getCopyCardRequest({
                    name,
                    id: copyFromId,
                    callbackSuccess: setForm,
                }),
            );

        id &&
            dispatch(
                getCardRequest({
                    name,
                    id,
                    callbackSuccess: card => {
                        setForm(card);
                        setNotChangeForm(true);
                        if (card.validationResult) {
                            card.validationResult &&
                                card.validationResult._errors &&
                                card.validationResult._errors.forEach(item => {
                                    dispatch(addError(item));
                                });
                        }
                    },
                    callbackNotFound: () => {
                        onClose();
                    }
                }),
            );

        id &&
            dispatch(
                getActionsRequest({
                    name,
                    ids: [id],
                    isCard: true,
                }),
            );

        id && dispatch(getHistoryRequest(id));
    };

    const reloadDocuments = (gridName, cardId) => {
        dispatch(getDocumentsRequest({ gridName, cardId }));
    };

    const onClose = () => {
        const { state } = location;
        const { pathname, gridLocation } = state;

        history.replace({
            pathname: pathname,
            state: {
                ...state,
                pathname: gridLocation,
            },
        });
    };

    const handleClose = isConfirm => {
        if (!isConfirm || notChangeForm) {
            onClose();
        } else {
            showConfirmation(
                t('confirm_close_dictionary'),
                () => {
                    closeConfirmation();
                    onClose();
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const onChangeForm = useCallback(
        (e, { name, value }) => {
            setForm(prevState => {
                let formNew = {
                    ...prevState,
                    [name]: value,
                };

                if (dependentFields[name] && dependentFields[name].length) {
                    dependentFields[name].forEach(item => {
                        formNew = {
                            ...formNew,
                            [item]: '',
                        };
                    });
                }

                const autocompleteFields = columns.filter(
                    i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
                );

                if (autocompleteFields && autocompleteFields.length && value) {
                    autocompleteFields.forEach(autocompleteField => {
                        formNew = {
                            ...formNew,
                            [autocompleteField.name]:
                                value[autocompleteField.autoComplete[name]] || '',
                        };
                    });
                }

                return formNew;
            });
        },
        [columns],
    );

    const saveWithConfirmation = (message, callbackFun) => {
        const formId = form.id;
        setConfirmation({
            open: true,
            content: message,
            onCancel: closeConfirmation,
            onConfirm: () => {
                closeConfirmation();
                dispatch(
                    editCardRequest({
                        name,
                        params: form,
                        callbackSuccess: callbackFun
                            ? callbackFun
                            : () => {
                                  if (form.id) {
                                      setNotChangeForm(true);
                                      loadCard();
                                      reloadDocuments(name, formId);
                                  } else {
                                      handleClose();
                                  }
                              },
                        isConfirmed: true,
                    }),
                );
            },
        });
    };

    const saveOrEditForm = callbackFun => {
        const formId = form.id;
        dispatch(
            editCardRequest({
                name,
                params: form,
                callbackSuccess: callbackFun
                    ? callbackFun
                    : () => {
                          if (form.id) {
                              setNotChangeForm(true);
                              loadCard();
                              reloadDocuments(name, formId);
                          } else {
                              handleClose();
                          }
                      },
                callbackConfirmation: (model) => saveWithConfirmation(model.confirmationMessage || model.message, callbackFun),
            }),
        );
    };

    const createForm = () => {
        dispatch(
            createGridCardRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            }),
        );
    };

    const handleSave = () => {
        setNotChangeForm(true);
        if (name === SHIPPINGS_GRID && !form.id) {
            createForm();
        } else {
            saveOrEditForm();
        }
    };

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const confirmAction = (actionName, actionDisplayName, model, confirmationModel) => {
        showConfirmation(
            confirmationModel.confirmationMessage || confirmationModel.message, 
            () => {
                closeConfirmation();
                invokeAction(actionName, actionDisplayName, model, true);
            },
            closeConfirmation
        );
    };

    const invokeConfirmedAction = (actionName, actionDisplayName, model, isConfirmed) => {
        const formId = id;
        dispatch(
            invokeActionRequest({
                ids: [id],
                name,
                actionName,
                isConfirmed,
                callbackSuccess: () => {
                    if (actionName.toLowerCase().includes('delete')) {
                        onClose();
                    } else {
                        loadCard();
                        reloadDocuments(name, formId);
                    }
                },
                callbackConfirmation: (confirmationModel) => confirmAction(actionName, actionDisplayName, model, confirmationModel)
            }),
        );
    };

    const invokeAction = (actionName, actionDisplayName, model, isConfirmed) => {
        let content = `${actionName}.modalContent`;
        if (model) {
            setFormModal({
                open: true,
                columns: model.columns,
                actionName,
                name,
                ids: [id],
                onClose: () => {
                    setFormModal({open: false})
                },
                backLoad: loadCard
            });
        } else if (isConfirmed) {
            invokeConfirmedAction(actionName, actionDisplayName, model, isConfirmed);
        } else {
            showConfirmation(
                t(content) !== content
                    ? t(content)
                    : `${t('Are you sure to complete')} "${t(actionDisplayName)}"?`,
                () => {
                    closeConfirmation();
                    invokeConfirmedAction(actionName, actionDisplayName, model, isConfirmed);
                },
                closeConfirmation,
            );
        }
    };

    const handleUniquenessCheck = callbackFunc => {
        dispatch(
            isUniqueNumberRequest({
                number: form.orderNumber,
                fieldName: 'orderNumber',
                errorText: t('number_already_exists'),
                callbackSuccess: callbackFunc,
            }),
        );
    };

    const handleCreateAndSend = () => {
        dispatch(
            createAndSendRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            }),
        );
    };

    const loading = useSelector(state => progressSelector(state));
    const editLoading = useSelector(state => editProgressSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const progressActionName = useSelector(state => progressActionNameSelector(state));
    const disableSave = useMemo(() => {
        return Boolean(progressActionName || notChangeForm);
    }, [progressActionName, notChangeForm]);

    const goToSoldTo = (id, params) => {
        const go = () => {
            const { state } = location;
            history.replace({
                pathname: id
                    ? DICTIONARY_CARD_LINK.replace(':name', 'warehouses').replace(':id', id)
                    : DICTIONARY_NEW_LINK.replace(':name', 'warehouses'),
                state: {
                    ...state,
                    pathname: history.location.pathname,
                    gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
                    ...params,
                },
            });
        };

        if (notChangeForm) {
            go();
        } else {
            showConfirmation(
                t('confirm_close'),
                () => {
                    closeConfirmation();
                    saveOrEditForm(go);
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const getActionsFooter = useCallback(() => {
        if (name === SHIPPINGS_GRID && !id) {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleSave}
                    >
                        {t('createShipping')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleCreateAndSend}
                    >
                        {t('createShippingAndSendToTk')}
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        }
    }, [form, disableSave, editLoading, name]);

    const goToCard = (gridName, cardId) => {
        const { state } = location;
        history.replace({
            pathname: GRID_CARD_LINK.replace(':name', gridName).replace(':id', cardId),
            state: {
                ...state,
                pathname: history.location.pathname,
                gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
            },
        });
    };

    const exportHistoryExcel = (historyType) => {
        dispatch(exportHistoryToExcelRequest({ name, ids: [form.id], historyType }));
    };

    const exportCarrierDocuments = () => {
        dispatch(exportCarrierDocumentsRequest({ name, ids: [form.id] }));
    };

    const invokeCarrierSelection = () => {
        const formId = form.id;
        dispatch(
            selectCarrierRequest({
                ids: [form.id],
                callbackSuccess: () => {
                    setNotChangeForm(true);
                    loadCard();
                    reloadDocuments(name, formId);
                },
            }),
        );
    };

    const getActionsHeader = useCallback(() => {
        return (
            <div className="grid-card-header">
                {name === ORDERS_GRID && form.showShippingLink && form.shippingId ? (
                    <Dropdown
                        text={shippingsDisplayName}
                        pointing="top right"
                        className="dropdown-blue"
                        scrolling
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                className="link-cell"
                                key={form.shippingId}
                                text={form.shippingNumber}
                                onClick={() => {
                                    goToCard(SHIPPINGS_GRID, form.shippingId);
                                }}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                ) : null}
                {name === SHIPPINGS_GRID && form.showOrderLinks && form.orders && form.orders.length ? (
                    <Dropdown
                        text={ordersDisplayName}
                        pointing="top right"
                        className="dropdown-blue"
                        scrolling
                    >
                        <Dropdown.Menu>
                            {form.orders.map(order => (
                                <Dropdown.Item
                                    className="link-cell"
                                    key={order.id}
                                    text={order.orderNumber}
                                    onClick={() => {
                                        goToCard(ORDERS_GRID, order.id);
                                    }}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : null}
                {name === SHIPPINGS_GRID && userPermissions.includes(135) && (
                    <>
                        <Popup
                            content={t('viewDeliveryCostRequests')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="edit"
                                    onClick={() => {
                                        setOpenDeliveryCostRequestsModal(true);
                                    }}
                                />
                            }
                        />
                        <Modal
                            closeIcon
                            size="large"
                            open={openDeliveryCostRequestsModal}
                            onClick={() => {
                                setOpenDeliveryCostRequestsModal(false);
                            }}
                        >
                            <Modal.Header>{t("deliveryCostRequests")}</Modal.Header>
                            <Modal.Content>
                                <DeliveryCostRequests id={id} />
                            </Modal.Content>
                        </Modal>
                    </>
                )}
                {form.canExportHistory && (
                    <Popup
                        content={t('exportHistoryToExcel')}
                        position="left center"
                        trigger={
                            name !== SHIPPINGS_GRID
                                ? <Button icon="history" onClick={() => exportHistoryExcel()} />
                                : <Dropdown
                                    icon='history'
                                    floating
                                    button
                                    className='icon'
                                    pointing='top right'
                                >
                                    <Dropdown.Menu>
                                        {
                                            ['General', 'CostHistory', 'Requests'].map(v => <Dropdown.Item
                                                content={t(`exportHistory${v}`)}
                                                onClick={() => exportHistoryExcel(v)}
                                            />)
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                        }
                    />
                )}
                {name === SHIPPINGS_GRID && form.canSelectCarrier ? (
                    <Button onClick={invokeCarrierSelection}>{t('selectCarrier')}</Button>
                ) : null}
                {name === SHIPPINGS_GRID && form.canExportCarrierDocuments ? (
                    <Popup
                        content={t('exportCarrierDocuments')}
                        position="bottom right"
                        trigger={
                            <Button icon="file archive" onClick={exportCarrierDocuments} />
                        }
                    />
                ) : null}
                <Dropdown
                    icon="ellipsis horizontal"
                    floating
                    button
                    pointing="top right"
                    className="icon"
                    scrolling
                >
                    <Dropdown.Menu>
                        {actions &&
                            actions
                                .filter(item => item.allowedFromForm)
                                .map(action => (
                                    <Dropdown.Item
                                        key={action.name}
                                        text={t(action.displayName || action.name)}
                                        label={{
                                            color: action.color,
                                            empty: true,
                                            circular: true,
                                        }}
                                        onClick={() => invokeAction(action.name, action.displayName || action.name, action.model)}
                                    />
                                ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }, [form, actions, name, openDeliveryCostRequestsModal]);

    const cardContent = () => {
        const params = {
            ...props,
            id,
            load: loadCard,
            name,
            form,
            title,
            settings: settings || {},
            loading,
            uniquenessNumberCheck: handleUniquenessCheck,
            error,
            goToSoldTo,
            onClose: handleClose,
            onChangeForm: onChangeForm,
            actionsFooter: getActionsFooter,
            actionsHeader: getActionsHeader,
        };

        const getContent = () => {
            switch (name) {
                case ORDERS_GRID:
                    return <OrderCard />;
                case SHIPPINGS_GRID:
                    return <ShippingCard />;
                default:
                    return <OtherCard />;
            }
        };

        return React.cloneElement(getContent(), params);
    };

    return (
        <React.Fragment>
            {cardContent()}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <ParamsFromActions formModal={formModal} />
        </React.Fragment>
    );
};

export default Card;
