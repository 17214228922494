import React, { useState } from 'react';
import { Button, Icon, Input, Popup } from 'semantic-ui-react';

const FieldCell = ({ field, t, changeSettings, extType, isDisabled, fieldName, renameField }) => {
    let [open, setOpen] = useState(false);
    let [isEdit, setIsEdit] = useState(false);
    let [currentName, setCurrentName] = useState(t(fieldName));

    let prevName = t(fieldName);

    const toggle = () => setOpen(prevState => !prevState);

    const startEdit = () => {
        setIsEdit(true);
    };

    const acceptEdit = () => {
        if (currentName !== prevName) {
            renameField && renameField(field, currentName, extType);
            prevName = currentName;
        }
        setIsEdit(false);
    };

    return (
        <>
            {!isEdit ?
                (<>
                    <div className="cell-field-name">{currentName}</div>
                    <Icon name="pencil" onClick={startEdit} />
                </>)
                : (<>
                    <Input
                        className='cell-field-name-edit'
                        value={currentName}
                        name={field}
                        type='text'
                        onChange={(e, {value}) => setCurrentName(value)}
                    />
                    <Icon name="check" onClick={acceptEdit} />
                </>)}
            <Popup
                trigger={
                    <Button
                        size="mini"
                        disabled={isDisabled}
                        className="margin-left-8"
                        onClick={toggle}
                    >
                        {t('All')}
                    </Button>
                }
                content={
                    <Button.Group>
                        {/*<Button
                            size="mini"
                            onClick={() => {
                                changeSettings(field, 'hidden', null, extType);
                            }}
                        >
                            {t('hidden')}
                        </Button>*/}
                        <Button
                            size="mini"
                            onClick={() => {
                                changeSettings(field, 'show', null, extType);
                                toggle();
                            }}
                        >
                            {t('show')}
                        </Button>
                        <Button
                            size="mini"
                            onClick={() => {
                                changeSettings(field, 'edit', null, extType);
                                toggle();
                            }}
                        >
                            {t('edit')}
                        </Button>
                    </Button.Group>
                }
                open={open}
                on="click"
                hideOnScroll
                position="top left"
            />
        </>
    );
};

export default React.memo(FieldCell);
