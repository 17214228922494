import React, {useCallback} from 'react';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import FormField from '../formField';
import { SHIPPINGS_GRID } from '../../../../constants/grids';
import { useSelector } from 'react-redux';
import { columnsCardTypesConfigSelector } from '../../../../ducks/representations';
import { isCustomPageSelector } from '../../../../ducks/profile';

const fields = [
    'basicDeliveryCostWithoutVAT',
    'downtimeRate',
    'orderCosts',
    'otherCosts',
];

const Costs = ({ form = {}, onChange, settings, error }) => {
    const { t } = useTranslation();

    const columnsConfig = useSelector(state => columnsCardTypesConfigSelector(state, SHIPPINGS_GRID));
    const pageRights = useSelector(state => isCustomPageSelector(state));

    const handleChange = useCallback((e, { name, value }) => {
        onChange(e, { name, value });

        if (fields.indexOf(name) < 0) {
            return;
        }

        let totalDeliveryCostWithoutVAT = 0;

        if (name !== 'orderCosts') {
            totalDeliveryCostWithoutVAT = value ? parseFloat(value) : 0;
        } else if (value && value.length) {
            value.forEach(item => {
                totalDeliveryCostWithoutVAT = totalDeliveryCostWithoutVAT + (item.returnCostWithoutVAT ? parseFloat(item.returnCostWithoutVAT) : 0);
            })
        }

        fields.filter(field => field !== name).forEach(field => {
            if (field !== 'orderCosts') {
                totalDeliveryCostWithoutVAT =
                    totalDeliveryCostWithoutVAT + (form[field] ? parseFloat(form[field]) : 0);
            } else if (form[field] && form[field].length) {
                form[field].forEach(item => {
                    totalDeliveryCostWithoutVAT = totalDeliveryCostWithoutVAT + (item.returnCostWithoutVAT ? parseFloat(item.returnCostWithoutVAT) : 0);
                })
            }

        });

        onChange(e, {
            name: 'totalDeliveryCostWithoutVAT',
            value: +totalDeliveryCostWithoutVAT.toFixed(2),
        });

        onChange(e, {
            name: 'totalDeliveryCost',
            value: +((parseFloat(totalDeliveryCostWithoutVAT) * 120) / 100).toFixed(2),
        });

    }, [form]);

    const renderField = (name, columnWidth, params = {}) => {
        if (settings && settings[name] === "hidden") {
            return null;
        }
        return (
            <Grid.Column width={columnWidth}>
                {
                    React.cloneElement(
                        <FormField
                            name={name}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />, 
                        { ...params }
                    )}
            </Grid.Column>
        );
    };

    const isAnyFieldVisible = (fields) => {
        if (fields && fields.length) {
            for (let index = 0; index < fields.length; index++) {
                if (settings && settings[fields[index]] !== "hidden") {
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <Form className="tabs-card">
            <Grid>
                {isAnyFieldVisible([
                        "registryNumber", 
                        "tarifficationType", 
                        "basicDeliveryCostWithoutVAT", 
                        "totalDeliveryCostWithoutVAT", 
                        "tariffCode", 
                        "costConditionId",
                        "currency",
                        "costsComments",
                        "newNegotiationAttemptCost",
                        "newNegotiationProposedCost",
                        "rejectComment",
                        "changeDeliveryCostStatus"
                    ]) && (
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t('Стоимость перевозки')}</label>
                                <Segment>
                                    <Grid>
                                        <Grid.Row className="field-row">
                                            {renderField("registryNumber", 4)}
                                            {renderField("tarifficationType", 4, { isTranslate: true })}
                                            {renderField("basicDeliveryCostWithoutVAT", 4)}
                                            {renderField("totalAdditionalCosts", 4)}
                                            {renderField("totalDeliveryCostWithoutVAT", 4)}
                                            {renderField("tariffCode", 4)}
                                            {renderField("costConditionId", 4)}
                                            {renderField("currency", 4)}
                                            {renderField("costsComments", 4)}
                                            {renderField("newNegotiationAttemptCost", 4)}
                                            {renderField("newNegotiationProposedCost", 4)}
                                            {renderField("carrierRejectCostComment", 4)}
                                            {renderField("changeDeliveryCostStatus", 4)}
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {isAnyFieldVisible([
                        "extraPointCostsWithoutVAT", 
                        "downtimeAmount", 
                        "loadingDowntimeCost", 
                        "unloadingDowntimeCost", 
                        "returnCostWithoutVAT", 
                        "otherCosts",
                        "reducingCoefficient",
                        "discount"
                    ]) && (
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t('Дополнительные расходы')}</label>
                                <Segment>
                                    <Grid>
                                        <Grid.Row className="field-row">
                                            {renderField("extraPointCostsWithoutVAT", 4, { onChange: handleChange })}
                                            {renderField("downtimeAmount", 4, { onChange: handleChange })}
                                            {renderField("loadingDowntimeCost", 4, { onChange: handleChange })}
                                            {renderField("unloadingDowntimeCost", 4, { onChange: handleChange })}
                                            {renderField("returnCostWithoutVAT", 4, { onChange: handleChange })}
                                            {renderField("otherCosts", 4, { onChange: handleChange })}
                                            {renderField("reducingCoefficient", 4, { onChange: handleChange })}
                                            {renderField("discount", 4, { onChange: handleChange })}
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                )}
                {pageRights.shippingsEditReturns ? (<Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('returnCostWithoutVAT')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row className="field-row">
                                        {renderField("orderCosts", 16, { onChange: handleChange, orders: form.orders })}
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>) : null}
            </Grid>
        </Form>
    );
};

export default Costs;
