import React, { useEffect, useState } from 'react';
import { Button, Form, Message, Modal, } from 'semantic-ui-react';
import FileUploader from './FileUploader';
import './style.scss';
import Select from "../BaseComponents/Select";
import Text from "../BaseComponents/Text";
import Date from "../BaseComponents/Date";
import {clearError, errorSelector} from "../../ducks/documents";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

const FileModal = ({
                       okButtonText,
                       titleText,
                       modalOpen,
                       onClose,
                       onSave,
                       disabled,
                       document: currentDocument,
                   }) => {
    
    let [document, setDocument] = useState(
        currentDocument ? Object.assign({}, currentDocument) : null,
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const error = useSelector(state => errorSelector(state));

    useEffect(
        () => {
            setDocument(currentDocument ? Object.assign({}, currentDocument) : null);
        },
        [currentDocument],
    );

    const handleClose = () => {
        onClose();
        setDocument(null);
    };

    const handleSave = () => {
        onSave(document, handleClose);
    }

    const addFile = (id, name) => {
        if (id) {
            setDocument({
                ...document,
                fileId: id,
                name: name,
            });
        }
    };

    const saveFileName = (e, { value }) => {
        if (!value || !document || !document.name || document.name.lastIndexOf('.') === -1) return;
        const currentName = document.name;
        const extension = currentName.substr(currentName.lastIndexOf('.'));

        setDocument({
            ...document,
            name: value + extension,
        });
    };

    const getFileName = () => {
        if (!document || !document.name) return '';
        const { name } = document;
        return name.lastIndexOf('.') !== -1 ? name.substr(0, name.lastIndexOf('.')) : '';
    };

    const handleChange = (e, { name, value }) => {
        setDocument({
            ...document,
            [name]: value,
        });
        dispatch(clearError(name));
    };
    
    return (
        <Modal
            size="mini"
            open={modalOpen}
            closeOnEscape
            closeOnDimmerClick={false}
            onClose={handleClose}
        >
            <Modal.Header>{titleText}</Modal.Header>
            <Modal.Content>
                <Form error={error && error['fileId']}>
                    <FileUploader document={document} onChange={addFile}/>
                    {error && error['fileId'] && <Message error content={error['fileId']}/>}
                    <Form.Field>
                        <Form.Input
                            label={t('Document name')}
                            placeholder={t('Upload file')}
                            name="name"
                            value={getFileName()}
                            onChange={saveFileName}
                            error={error['name']}
                            disabled={disabled}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Select
                            name="type"
                            text={t('Type')}
                            source='documentTypes'
                            value={document ? document.type : null}
                            onChange={handleChange}
                            error={error['type']}
                            disabled={disabled}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Text
                            name="number"
                            value={document ? document.number : null}
                            onChange={handleChange}
                            error={error['number']}
                            disabled={disabled}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Date
                            name="date"
                            value={document ? document.date : null}
                            onChange={handleChange}
                            error={error['date']}
                            disabled={disabled}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button icon="ban" content={t('CancelButton')} onClick={handleClose} disabled={disabled} />
                <Button icon="check" positive content={okButtonText} onClick={handleSave} disabled={disabled} />
            </Modal.Actions>
        </Modal>
    );
};

export default FileModal;