import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TableInfo from '../../../../components/TableInfo';
import { isCustomPageSelector } from '../../../../ducks/profile';
import {
    deliveryCostRequestsSelector,
    editProgressSelector,
    getDeliveryCostRequests,
    progressSelector
} from "../../../../ducks/gridCard";

const DeliveryCostRequests = props => {
    const { id } = props;
    
    const dispatch = useDispatch();

    const customPage = useSelector(state => isCustomPageSelector(state));
    const loading = useSelector(state => progressSelector(state));
    const deliveryCostRequests = useSelector(state => deliveryCostRequestsSelector(state));

    useEffect(() => {
        dispatch(getDeliveryCostRequests(id));
    }, []);
    
    return (
        <div className="tabs-card_table">
            <TableInfo
                loading={loading}
                key="deliveryCostRequests"
                name="deliveryCostRequests"
                className='compact-container'
                headerRow={customPage.deliveryCostRequestsColumns}
                list={deliveryCostRequests}
                isSubTable={true}
                canEdit={false}
            />
        </div>
    );
};

export default DeliveryCostRequests;
