import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Label } from 'semantic-ui-react';

const TableCell = ({
    value, 
    onChanged, 
    inputClassName = '',
    type = 'text',
}) => {
    const { t } = useTranslation();
    let [error, setError] = useState([]);
    let [isEdit, setIsEdit] = useState(false);
    let [currentValue, setCurrentValue] = useState(value);

    let prevValue = value;

    const startEdit = () => {
        setIsEdit(true);
    };

    const acceptEdit = () => {

        if (currentValue !== prevValue) {
            onChanged(currentValue);
            prevValue = currentValue;
        }

        setIsEdit(false);
    };

    return (
        <>
            {!isEdit ?
                (<>
                    <div className="cell-field-name">{currentValue}</div>
                    <Icon name="pencil" onClick={startEdit} />
                </>)
                : (<>
                    <Input
                        className={inputClassName + ' cell-field-name-edit'}
                        value={currentValue}
                        type={type}
                        onChange={(e, {value}) => setCurrentValue(value)}
                    />
                    <Icon name="check" onClick={acceptEdit} />
                </>)}
        </>
    );
};

export default React.memo(TableCell);
