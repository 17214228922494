import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {getUserProfile, roleIdSelector} from "../../ducks/profile";
import {companyListSelector, getLookupRequest} from "../../ducks/lookup";
import {fieldsSettingCompaniesSelector, getFieldsSettingCompaniesRequest} from '../../ducks/fieldsSetting';
import {
    getNotificationsSettingsRequest, 
    editNotificationSettingsRequest,
    editProgressSelector, 
    progressSelector, 
    notificationsSettingSelector, 
    clearNotificationsSettings
} from "../../ducks/notificationsSetting";

import {Loader, Table} from "semantic-ui-react";
import Header from "../notificationsSettings/components/header";
import TableHeader from "../notificationsSettings/components/table_header";
import {sortFunc} from "../../utils/sort";
import TableBody from "../notificationsSettings/components/table_body";
import TableCell from "./components/table_cell";
import CellValue from "../../components/ColumnsValue";
import {ACTIVE_TYPE} from "../../constants/columnTypes";

const List = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const containerRef = useRef(null);

    let [role, setRole] = useState(null);
    let [company, setCompany] = useState(null);
    
    const rolesList = useSelector(state => companyListSelector(state, 'roles', company)) || [];
    const companiesList = useSelector(state => fieldsSettingCompaniesSelector(state)) || [];
    const loading = useSelector(state => progressSelector(state));
    const userRole = useSelector(state => roleIdSelector(state));
    const notificationsSettings = useSelector(state => notificationsSettingSelector(state)) || [];

    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: 'roles',
                isForm: true,
            }),
        );

        dispatch(
            getFieldsSettingCompaniesRequest(),
        );
    }, [userRole]);
    
    useEffect(() => {
        if (rolesList.length) {
            setRole(rolesList[0].value);
        }
    }, [company]);

    useEffect(() => {
        if (!role && rolesList.length) {
            setRole(userRole);
        }
    }, [rolesList]);

    useEffect(() => {
        if (!company && companiesList.length) {
            setCompany(companiesList[0].value);
        }
    }, [companiesList]);

    useEffect(() => {
        dispatch(clearNotificationsSettings());
        role && getSettings();
    }, [role]);

    const getSettings = () => {
        dispatch(
            getNotificationsSettingsRequest({
                roleId: role,
                companyId: company,
            }),
        );
    };
    
    const handleChangeRole = useCallback((e, { value }) => {
        setRole(value);
    }, []);

    const handleChangeCompany = useCallback((e, { value }) => {
        setCompany(value);
    }, []);

    const handleChangSettings = useCallback(
        (notificationSetting) => {
            dispatch(
                editNotificationSettingsRequest({
                    params: {
                        type: notificationSetting.type,
                        name: notificationSetting.name,
                        isRequired: notificationSetting.isRequired,
                        isHidden: notificationSetting.isHidden,
                        roleId: role === 'null' ? undefined : role,
                        companyId: company === 'null' ? undefined : company,
                    },
                    callbackSuccess: () => {
                        getSettings();
                    },
                }),
            );
        },
        [role, company],
    );
    
    return (
        <div className="container ui">
            <Loader active={loading && !notificationsSettings.length} size="huge" className="table-loader">
                Loading
            </Loader>
            <Header
                rolesList={rolesList}
                companiesList={companiesList}
                role={role}
                company={company}
                changeRole={handleChangeRole}
                changeCompany={handleChangeCompany}
                t={t}
            />

            <div className={`scroll-table-container field-settings-table`} ref={containerRef}>
                <Table
                    className="ui celled selectable table grid-table table-info"
                    structured
                >
                    <Table.Header>
                        <TableHeader t={t} />
                    </Table.Header>
                    <Table.Body className="table-fields-setting">
                        {notificationsSettings.length ? (
                            <>
                                {notificationsSettings.map(group => (
                                    <>
                                        <Table.Row>
                                            <Table.Cell className="field-settings-table_fixed">
                                                <div className="ui ribbon label">{t(group.name)}</div>
                                            </Table.Cell>
                                            <Table.Cell>
                                            </Table.Cell>
                                            <Table.Cell>
                                            </Table.Cell>
                                        </Table.Row>
                                        {group.settings.map(setting => (
                                            <TableBody key={setting.type}
                                                       setting={setting}
                                                       onChangeSettings={handleChangSettings}
                                                       t={t}
                                            />
                                        ))}
                                    </>
                                ))}
                            </>
                        ) : null}
            
                    </Table.Body>
                </Table>
            </div>

        </div>
    );
};

export default List;