import { all, put, takeEvery } from 'redux-saga/effects';
import { postman } from '../utils/postman';
import { createSelector } from 'reselect';

const TYPE_API = 'settings';

const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';

const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST';
const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
const SAVE_SETTINGS_ERROR = 'SAVE_SETTINGS_ERROR';

//*  INITIAL STATE  *//

const initial = {
    list: [],
    error: [],
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
                error: [],
            };
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                list: payload,
                progress: false,
                error: [],
            };
        case GET_SETTINGS_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        case SAVE_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
                error: [],
            };
        case SAVE_SETTINGS_SUCCESS:
            return {
                ...state,
                progress: false,
                error: [],
            };
        case SAVE_SETTINGS_ERROR:
            return {
                ...state,
                progress: false,
                error: payload,
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getSettingsRequest = payload => {
    return {
        type: GET_SETTINGS_REQUEST,
        payload,
    };
};

export const saveSettingsRequest = payload => {
    return {
        type: SAVE_SETTINGS_REQUEST,
        payload,
    };
};

const stateSelector = state => state.settings;

export const settingsSelector = createSelector(stateSelector, state => state.list);
export const settingsProgressSelector = createSelector(stateSelector, state => state.progress);

//*  SAGA  *//

function* getSettingsSaga({payload}) {
    try {
        const result = yield postman.get(`/${TYPE_API}/${payload}`, {});

        yield put({
            type: GET_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_SETTINGS_ERROR,
            payload: error,
        });
    }
}

function* saveSettingsSaga({ payload: {companyId, settings} }) {
    try {
        const result = yield postman.post(`/${TYPE_API}/${companyId}`, settings);

        yield put({
            type: SAVE_SETTINGS_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: SAVE_SETTINGS_ERROR,
            payload: error,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_SETTINGS_REQUEST, getSettingsSaga),
        takeEvery(SAVE_SETTINGS_REQUEST, saveSettingsSaga),
    ]);
}