import React, { useEffect, useRef } from 'react';
import { Dropdown, Form, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLookupRequest, valuesListSelector } from '../../ducks/lookup';

const State = ({
    value,
    name,
    isDisabled,
    hintKey,
    onChange,
    className,
    text,
    source,
    placeholder,
    isRequired,
    error,
    noLabel,
    isGrid,
    formRef,
}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    let stateColors = useSelector(state => valuesListSelector(state, source)) || [];

    const selectRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            isGrid && selectRef && selectRef.current && selectRef.current.handleFocus();
        }, 100)

        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                isState: true,
            }),
        );
    }, []);

    const state = stateColors.find(x => x.name === value);
    const color = state ? state.color : 'grey';
    const items = (stateColors || []).map(x => {
        return {
            key: x.name,
            value: x.name,
            text: t(x.name),
            label: { color: x.color, empty: true, circular: true },
        };
    });

    const handleChange = (e, data) => {
        onChange(e, data);
        if (isGrid) {
            setTimeout(() => {
                formRef &&
                formRef.current &&
                formRef.current.handleSubmit &&
                formRef.current.handleSubmit();
            }, 100)
        }
    };

    if (!isDisabled)
        return (
            <Form.Field>
                {
                    !noLabel
                    ? <label className={isDisabled ? 'label-disabled' : null}>
                            <span dangerouslySetInnerHTML={{ __html: `${t(text || name)}${isRequired ? ' *' : ''}` }} />
                        </label>
                        : null
                }
                <Dropdown
                    ref={selectRef}
                    placeholder={t(placeholder)}
                    className={`${className}${isGrid ? ' state-field' : ''}`}
                    selection
                    search
                    error={error}
                    disabled={isDisabled || false}
                    name={name}
                    value={value}
                    fluid
                    options={items}
                    transparent={isGrid}
                    onChange={handleChange}
                    style={isGrid ? {width: `${(Math.max(...items.map(i => i.text.length)) + 1) * 8 + 42}px`, borderTopWidth: '1px'} : null}
                />
                {hintKey ? (
                    <span className="label-hint">{t(hintKey)}</span>
                ) : null}
                {hintKey && error && typeof error === 'string' && <br/>}
                {error && typeof error === 'string' ? (
                    <span className="label-error">{error}</span>
                ) : null}
            </Form.Field>
        );
    else
        return (
            <Form.Field>
                <label>{t(text || name)}</label>
                <div className="semantic-ui-div state-edit-field">
                    <Icon color={color} name="circle" />
                    {t(value)}
                </div>
            </Form.Field>
        );
};
export default State;
