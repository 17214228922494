import React from 'react'
import FormField from '../../../components/BaseComponents';

const CardContent = ({ columns, error, form, handleChange, canEdit }) => {

console.debug('error', error);

    return (
        <div className="ui form dictionary-edit">
            {columns.map(column => {
                let newColumn = {
                    ...column,
                    isReadOnly: !canEdit || column.isReadOnly
                };

                let sourceParams = {};
                if (column.dependencies && column.dependencies.length) {
                    column.dependencies.forEach(item => {
                        sourceParams = {
                            ...sourceParams,
                            [item]:
                                form[item] && typeof form[item] === 'object'
                                    ? form[item].value
                                    : form[item],
                        };
                    });
                }

                if (form.companyId && form.companyId.value) {
                    sourceParams.companyId = form.companyId.value;
                }

                if (sourceParams)
                {
                    newColumn = {
                        ...newColumn,
                        sourceParams,
                    };
                }

                return (
                    <FormField
                        {...newColumn}
                        noScrollColumn={column}
                        key={column.name}
                        error={error[column.name]}
                        value={form[column.name]}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};


export default CardContent
