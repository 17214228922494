import React, { useCallback, useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import FieldCell from './field_cell';
import CellValue from '../../../components/ColumnsValue';
import { ACTIVE_TYPE, INTEGER_TYPE } from '../../../constants/columnTypes';
import _, { uniq } from 'lodash';

const TableBody = ({
    fieldName, 
    column,
    toggleFieldFlags,
    changeSettings,
    changeMaxLength
}) => {
    let [hidden, setHidden] = useState(column.isHidden);
    let [required, setRequired] = useState(column.isRequired);
    let [unique, setUnique] = useState(column.isUnique);
    let [readOnly, setReadOnly] = useState(column.isReadOnly);
    let [fieldDisplayName, setFieldDisplayName] = useState(column.displayName);

    useEffect(
        () => {
            setHidden(column.isHidden);
            setRequired(column.isRequired);
            setUnique(column.isUnique);
            setReadOnly(column.isReadOnly);
        },
        [column.isHidden, column.isRequired, column.isUnique, column.isReadOnly],
    );

    useEffect(
        () => {
            if (hidden !== column.isHidden) {
                toggleFieldFlags(column.fieldName, 'hidden', hidden);
            }
        },
        [hidden],
    );

    useEffect(
        () => {
            if (required !== column.isRequired) {
                toggleFieldFlags(column.fieldName, 'required',  required);
            }
        },
        [required],
    );

    useEffect(
        () => {
            if (unique !== column.isUnique) {
                toggleFieldFlags(column.fieldName, 'unique', unique);
            }
        },
        [unique],
    );

    useEffect(
        () => {
            if (readOnly !== column.isReadOnly) {
                toggleFieldFlags(column.fieldName, 'readonly', readOnly);
            }
        }, [readOnly],
    );

    // useEffect(
    //     () => {
    //         console.debug('fieldDisplayName', fieldDisplayName);
    //         changeSettings(fieldName, fieldDisplayName);
    //     }, [fieldDisplayName],
    // );

    const handleEditName = (name, value) => {
        console.debug('name', value);
        changeSettings(name, value);
    }

    const handleEditMaxLength = (name, value) => {
        changeMaxLength(name, value);
    }

    return (
        <Table.Row key={column.fieldName}>
            <Table.Cell className="">
                <FieldCell
                    fieldName={column.fieldName}
                    fieldDescriptionKey={column.descriptionKey}
                    value={column.displayName}
                    onChanged = {handleEditName}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={hidden}
                    type={ACTIVE_TYPE}
                    isDisabled={column.isFixedSettings}
                    toggleIsActive={() => setHidden(!hidden)}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={required}
                    type={ACTIVE_TYPE}
                    isDisabled={column.isCalculated || hidden || column.isFixedSettings}
                    toggleIsActive={() => setRequired(!required)}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={unique}
                    type={ACTIVE_TYPE}
                    isDisabled={column.isCalculated || hidden || column.isFixedSettings}
                    toggleIsActive={() => setUnique(!unique)}
                />
            </Table.Cell>
            <Table.Cell>
                <CellValue
                    value={readOnly}
                    type={ACTIVE_TYPE}
                    isDisabled={column.isCalculated || hidden || column.isFixedSettings}
                    toggleIsActive={() => setReadOnly(!readOnly)}
                />
            </Table.Cell>
            <Table.Cell>
                <FieldCell
                    type='number'
                    inputClassName='cell-number-field'
                    fieldName={column.fieldName}
                    value={column.maxLength}
                    onChanged = {handleEditMaxLength}
                />
            </Table.Cell>
        </Table.Row>
    );
};

export default React.memo(TableBody, (prevProps, currentProps) => {
    return _.isEqual(prevProps.column, currentProps.column)
});
