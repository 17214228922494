export const LOGIN_LINK = '/login';
export const ROLES_LINK = '/roles';
export const ROLE_LINK = '/roles/:id';
export const NEW_ROLE_LINK = '/roles/new';
export const USERS_LINK = '/users';
export const USER_LINK = '/users/:id';
export const NEW_USER_LINK = '/users/new';
export const PROFILE_LINK = '/profile';
export const GRID_LIST_LINK = '/grid/:name';
export const GRID_CARD_LINK = '/grid/:name/:id';
export const GRID_NEW_LINK = '/grid/:name/new';
export const GRID_AUTO_GROUPING_LINK = '/grid/:name/autogrouping';
export const REPORT_LINK = '/report/:type';
export const LOADER_LINK = '/loader/:type';
export const DICTIONARY_LIST_LINK = '/dictionary/:name';
export const DICTIONARY_CARD_LINK = '/dictionary/:name/:id';
export const DICTIONARY_NEW_LINK = '/dictionary/:name/new';
export const DICTIONARY_ARTICLES_LIST_LINK = '/articles';
export const DICTIONARY_ARTICLES_CARD_LINK = '/articles/:id';
export const DICTIONARY_ARTICLES_NEW_LINK = '/articles/new';
export const DICTIONARY_TARIFFS_LIST_LINK = '/tariffs';
export const DICTIONARY_TARIFFS_CARD_LINK = '/tariffs/:id';
export const DICTIONARY_TARIFFS_NEW_LINK = '/tariffs/new';
export const NOTIFICATIONS_SETTING_LINK = '/notifications_setting';
export const FIELDS_SETTING_LINK = '/fields_setting';
export const SCENARIO_SETTING_LINK = '/scenario_setting';
export const DICTIONARY_SETTING_LINK = '/dictionary_setting';
export const COMPANY_NEW_LINK = '/dictionary/companies/new';
export const COMPANY_LINK = '/dictionary/companies/:id';
export const SETTINGS_LINK = '/settings/:companyId';
export const SHIPMENT_REGISTER_LINK = '/shipmentRegister';
export const CHANGE_PASSWORD_LINK = '/changePassword';

export const customPage = [
    FIELDS_SETTING_LINK, 
    DICTIONARY_SETTING_LINK, 
    GRID_AUTO_GROUPING_LINK, 
    SETTINGS_LINK,
    CHANGE_PASSWORD_LINK
];
