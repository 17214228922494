import React, { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import {
    BIG_TEXT_TYPE,
    DATE_TIME_TYPE,
    NUMBER_TYPE,
    STATE_TYPE,
    ENUM_TYPE,
    TEXT_TYPE,
} from '../../../../constants/columnTypes';
import {clearError, settingsExtSelector} from '../../../../ducks/gridCard';
import { fieldsSettingSelector } from '../../../../ducks/fieldsSetting';
import { useTranslation } from 'react-i18next';

const Route = ({
    name,
    form = {},
    point = {},
    onChange,
    pointChange,
    index,
    settings: baseSettings,
    error,
}) => {
    const { t } = useTranslation();
    const extType = point.isLoading ? 'loadingRoutePoints' : 'unloadingRoutePoints';
    const settings = useSelector(state => settingsExtSelector(state, form.status, extType));

    const fieldSettings = useSelector(state => fieldsSettingSelector(state));
    const { ext = {} } = fieldSettings;
    let columnNames = {};
    if (ext && ext[extType])
    {
        ext[extType].forEach(column => {
            columnNames = {
                ...columnNames,
                [column.fieldName]: column.displayName || t(column.fieldName),
            };
        });
    }

    const dispatch = useDispatch();

    const handleChange = useCallback(
        (e, { name, value }) => {
            if (error[`${name}_${index}`]) {
                dispatch(clearError && clearError(`${name}_${index}`));
                const e = Object.keys(error).filter(i => i.includes('plannedDate'));

                if (e && e.length) {
                  e.forEach(i => {
                      dispatch(clearError && clearError(i));
                  })
                }
            }
            pointChange(
                {
                    ...point,
                    [name]: value,
                },
                index,
            );
        },
        [point, error],
    );

    const renderField = (name, index, type, columnWidth, params = {}) => {
        if (settings && settings[name] === "hidden") {
            return null;
        }
        if (columnNames[name]) {
            params = {
                ...params,
                text: columnNames[name]
            }
        }
        return (
            <Grid.Column width={columnWidth}>
                {
                    React.cloneElement(
                        <FormField
                            name={name}
                            value={point[name]}
                            error={error[`${name}_${index}`]}
                            type={type}
                            settings={settings[name]}
                            onChange={handleChange}
                        />, 
                        { ...params }
                    )}
            </Grid.Column>
        );
    };

    return (
        <Form style={{ paddingLeft: '12px' }}>
            <Grid>
                <Grid.Row className="field-row">
                    {renderField("code", index, TEXT_TYPE, 5)}
                    {renderField("operationType", index, TEXT_TYPE, 5)}
                    {renderField("vehicleStatus", index, STATE_TYPE, 5, { source: 'vehicleState' })}
                    {renderField("plannedDate", index, DATE_TIME_TYPE, 8)}
                    {renderField("startOperationTime", index, DATE_TIME_TYPE, 8, { maxDate: point['endOperationTime'] })}
                    {renderField("endOperationTime", index, DATE_TIME_TYPE, 8, { minDate: point['startOperationTime'] })}
                    {renderField("plannedDepartureTime", index, DATE_TIME_TYPE, 8, { minDate: point['plannedDate'] })}
                    {renderField("arrivalTime", index, DATE_TIME_TYPE, 8, { maxDate: point['departureTime'] })}
                    {renderField("actualStartOperationTime", index, DATE_TIME_TYPE, 8, { maxDate: point['actualEndOperationTime'] })}
                    {renderField("actualEndOperationTime", index, DATE_TIME_TYPE, 8, { minDate: point['actualStartOperationTime'] })}
                    {renderField("departureTime", index, DATE_TIME_TYPE, 8, { minDate: point['arrivalTime'] })}
                    {renderField("trucksDowntime", index, NUMBER_TYPE, 8)}
                    {renderField("scheduledUnloadingEndTime", index, DATE_TIME_TYPE, 8, { isDisabled: true })}
                    {renderField("warehouseCode", index, TEXT_TYPE, 8)}
                    {renderField("warehouseName", index, TEXT_TYPE, 8)}
                    {renderField("warehouseCategory", index, TEXT_TYPE, 8)}
                    {renderField("warehouseZone", index, TEXT_TYPE, 8)}
                    {renderField("country", index, TEXT_TYPE, 8)}
                    {renderField("region", index, TEXT_TYPE, 8)}
                    {renderField("address", index, BIG_TEXT_TYPE, 8)}
                    {renderField("comment", index, BIG_TEXT_TYPE, 8)}
                    {renderField("orderNumbers", index, BIG_TEXT_TYPE, 8, { text: 'order_numbers' })}
                    {renderField("orderClientNumbers", index, BIG_TEXT_TYPE, 8)}
                    {point.isLoading && renderField("loadingDowntimeReason", index, ENUM_TYPE, 8, { source: 'downtimeReason' })}
                    {!point.isLoading && renderField("unloadingDowntimeReason", index, ENUM_TYPE, 8, { source: 'downtimeReason' })}
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Route;
