import React, { useEffect, useState } from 'react';
import { Button, Confirm, Grid, Modal } from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getActionModelRequest, invokeActionRequest } from "../../../../ducks/gridActions";
import { errorMapping } from "../../../../utils/errorMapping";
import { showModal } from '../../../../ducks/modal';

const ParamsFromActions = ({ formModal }) => {
    const {ids, name, actionName, columns = [], open, onClose, backLoad} = formModal;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [params, setParams] = useState({});
    const [errors, setErrors] = useState({});
    const [confirmation, setConfirmation] = useState({ open: false });
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        open && dispatch(getActionModelRequest({
            ids, 
            name, 
            actionName, 
            callbackSuccess: (model) => {
                model && setParams(model);
            }
        }));
    }, [open]);

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
        setInProgress(false);
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const handleClose = () => {
        setErrors({});
        setParams({});
        setInProgress(false);
        onClose();
    };

    const handleChange = (e, { name, value }) => {

        setParams(prev => {
            let form = {
                ...prev,
                [name]: value,
            };

            const autocompleteFields = columns.filter(
                i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
            );

            if (autocompleteFields && autocompleteFields.length && value) {
                autocompleteFields.forEach(autocompleteField => {
                    form = {
                        ...form,
                        [autocompleteField.name]: value[autocompleteField.autoComplete[name]] || '',
                    };
                });
            }

            return form;
        });


        setErrors(prevErrors => prevErrors && prevErrors[name]
            ? Object.keys(prevErrors)
                .filter(i => i !== name)
                .map(i => prevErrors[i])
            : prevErrors)
    };

    const confirmAction = (model) => {
        if (model.needConfirmationIds) {
            dispatch(showModal({
                ...model,
                confirmationCallback: () => handleConfirm(true, model.needConfirmationIds)
            }));
        } else {
            showConfirmation(
                model.confirmationMessage || model.message, 
                () => {
                    closeConfirmation();
                    handleConfirm(true);
                },
                closeConfirmation
            );
        }
    };

    const handleConfirm = (isActionConfirmed, confirmedIds) => {
        setInProgress(true);
        dispatch(
            invokeActionRequest({
                ids: confirmedIds || ids,
                name: name,
                model: params,
                actionName,
                isConfirmed: isActionConfirmed,
                callbackError: errors => {
                    setErrors(errorMapping(errors));
                    setInProgress(false);
                },
                callbackSuccess: () => {
                    handleClose();
                    backLoad && backLoad();
                },
                callbackConfirmation: confirmAction
            }),
        );
    };

    const renderField = (column, columnWidth) => {
        return (
            <Grid.Column width={columnWidth}>
                {
                    React.cloneElement(
                        <FormField
                            {...column}
                            key={column.name}
                            error={errors && errors[column.name]}
                            value={params[column.name]}
                            onChange={handleChange}
                            isDisabled={inProgress}
                        />, 
                        { ...params }
                    )}
            </Grid.Column>
        );
    };

    return (
        <>
            <Modal open={open} size='tiny'>
                <Modal.Header>{t('fillForm')}</Modal.Header>
                <Modal.Content>
                    <div className="ui form dictionary-edit" style={{ padding: 0 }}>
                        <Grid style={{ width: '100%' }}>
                            <Grid.Row className="field-row">
                                {columns.map(column => renderField(column, 16))}
                            </Grid.Row>
                        </Grid>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        onClick={handleClose}
                        disabled={inProgress}
                    >
                        {t('CancelButton')}
                    </Button>
                    <Button
                        primary
                        loading={inProgress}
                        disabled={
                            inProgress || !columns.filter(i => i.isRequired).every(i => params[i.name])
                        }
                        onClick={() => handleConfirm()}
                    >
                        {t('Ok')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </>
    );
};

export default ParamsFromActions;
