import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm, Icon, Popup } from 'semantic-ui-react';
import DocView from './DocView';
import './style.scss';
import {
    addDocumentRequest,
    deleteDocumentRequest,
    downloadDocumentRequest,
    editDocumentRequest,
    progressSelector,
} from '../../ducks/documents';
import FileModal from "./FileModal";

const DocWithEditor = ({
    okButtonText,
    titleText,
    document: currentDocument,
    gridName,
    cardId,
    getDocuments,
    isEditPermissions,
    load,
}) => {
    let [modalOpen, setModalOpen] = useState(false);
    let [confirmation, setConfirmation] = useState({ open: false });
    let [document, setDocument] = useState(
        currentDocument ? Object.assign({}, currentDocument) : null,
    );

    const isFileProgress = useSelector(state => progressSelector(state));

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(
        () => {
            setDocument(currentDocument ? Object.assign({}, currentDocument) : null);
        },
        [currentDocument],
    );

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        load && load();
        getDocuments();
        setModalOpen(false);
    };

    const handleSave = (document, handleClose) => {
        if (document && document.fileId) {
            document.id
                ? dispatch(
                      editDocumentRequest({
                          gridName,
                          cardId,
                          document: {
                              id: document.id,
                              name: document.name,
                              fileId: document.fileId,
                              type: document.type,
                              number: document.number,
                              date: document.date,
                          },
                          id: document.id,
                          callbackSuccess: () => {
                              handleClose();
                          },
                      }),
                  )
                : dispatch(
                      addDocumentRequest({
                          gridName,
                          cardId,
                          document: {
                              name: document.name,
                              fileId: document.fileId,
                              type: document.type,
                              number: document.number,
                              date: document.date,
                          },
                          callbackSuccess: () => {
                              handleClose();
                          },
                      }),
                  );
        }
    };

    const handleDownload = () => {
        dispatch(downloadDocumentRequest({ id: document.fileId }));
    };

    const handleDelete = document => {
        setConfirmation({
            open: true,
            content: t('Delete document', { name: document.name }),
            cancel: () => {
                setConfirmation({ open: false });
            },
            confirm: () => {
                dispatch(
                    deleteDocumentRequest({
                        gridName,
                        cardId,
                        id: document.id,
                        callbackSuccess: () => {
                            getDocuments();
                            load && load();
                            setConfirmation({ open: false });
                        },
                    }),
                );
            },
        });
    };

    return (
        <DocView document={currentDocument}>
            {currentDocument ? (
                <div>
                    {isEditPermissions ? (
                        <Popup
                            content={t('delete')}
                            position="bottom right"
                            trigger={
                                <Icon
                                    name="times"
                                    className="uploaded-image-delete-button"
                                    onClick={() => handleDelete(currentDocument)}
                                />
                            }
                        />
                    ) : null}

                    {isEditPermissions ? (
                        <Popup
                            content={t('edit')}
                            position="bottom right"
                            trigger={
                                <Icon
                                    name="pencil alternate"
                                    className="uploaded-image-edit-button"
                                    onClick={handleOpenModal}
                                />
                            }
                        />
                    ) : null}

                    <Popup
                        content={t('download')}
                        position="bottom right"
                        trigger={
                            <Icon
                                name="download"
                                className="uploaded-image-download-button"
                                onClick={handleDownload}
                            />
                        }
                    />
                </div>
            ) : (
                <Icon name="plus" className="uploaded-image-add-button" onClick={handleOpenModal} />
            )}
            <FileModal
                okButtonText={okButtonText}
                titleText={titleText}
                modalOpen={modalOpen}
                onClose={handleCloseModal}
                onSave={handleSave}
                document={currentDocument}
                disabled={isFileProgress}
            />
            <Confirm
                open={confirmation.open}
                content={confirmation.content}
                onCancel={confirmation.cancel}
                cancelButton={t('cancelConfirm')}
                onConfirm={confirmation.confirm}
            />
        </DocView>
    );
};

export default DocWithEditor;
