import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Confirm, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearUserCard,
    getUserCardRequest,
    progressSelector,
    userCardSelector,
    errorSelector,
    createUserRequest,
    saveProgressSelector, generateKeyRequest, getUserCardDefaultValueRequest,
} from '../../ducks/users';
import {toast} from "react-toastify";
import { 
    canEditSelector, 
    canUseDocumentsSelector, 
    canUseHistorySelector,
    clearDictionaryCard, 
    columnsCardSelector, 
    getCardConfigRequest, 
} from '../../ducks/dictionaryView';
import UserTabs from './userTabs';

const UserCard = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { 
        match, 
        history, 
        location, 
        openModal 
    } = props;
    const { params = {} } = match;
    const { name = 'users', id } = params;
    const { state } = location;
    const { defaultForm, columns: propsColumns, copyFromId } = (state || {});

    let [form, setForm] = useState({ ...defaultForm });
    let [confirmation, setConfirmation] = useState({ open: false });
    let [notChangeForm, setNotChangeForm] = useState(true);

    const columns = useSelector(state =>
        propsColumns ? propsColumns : columnsCardSelector(state, name, id),
    );
    const loading = useSelector(state => progressSelector(state));
    const progress = useSelector(state => saveProgressSelector(state));
    const user = useSelector(state => userCardSelector(state));
    const error = useSelector(state => errorSelector(state)) || {};
    const canEdit = useSelector(state => canEditSelector(state, name));
    const useDocuments = useSelector(state => canUseDocumentsSelector(state, name));
    const useHistory = useSelector(state => canUseHistorySelector(state, name));

    useEffect(() => {
        id && dispatch(getCardConfigRequest({ id, name }));
        id
            ? dispatch(getUserCardRequest(id))
            : copyFromId
                ? dispatch(getUserCardRequest(copyFromId))
                : dispatch(getUserCardDefaultValueRequest(name));

        return () => {
            dispatch(clearUserCard());
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(
        () => {
            if (copyFromId) {
                user.id = null;
                setNotChangeForm(false);
            }

            setForm(form => ({
                ...form,
                ...user,
            }));
        },
        [user],
    );

    const title = useMemo(
        () => (id ? t('edit_user', { name: user.userName }) : `${t('create_user_title')}`),
        [id, user],
    );

    const getActionsHeader = () => {
        return (
            <div>
                {user.id && user.openApiTokenGenerate && <Button onClick={generateKey}>{t('generate_key_open_api')}</Button>}
            </div>
        );
    };

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={notChangeForm}
                        loading={progress}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form, notChangeForm, progress],
    );

    const handleSave = () => {
        let params = { ...form };

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        dispatch(
            createUserRequest({
                params,
                callbackFunc: onClose,
            }),
        );
    };

    const handleChange = useCallback(
        (event, {name, value}) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }

            if (name === 'companyId') {
                handleChange(event, { name: 'carrierId', value: null });
                handleChange(event, { name: 'shippingWarehouseIds', value: null });
                handleChange(event, { name: 'roleId', value: null });
            }

            let formNew = {
                ...form,
                [name]: value,
            };

            const autocompleteFields = columns.filter(
                i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
            );

            if (autocompleteFields && autocompleteFields.length && value) {
                autocompleteFields.forEach(autocompleteField => {
                    formNew = {
                        ...formNew,
                        [autocompleteField.name]: value[autocompleteField.autoComplete[name]] || '',
                    };
                });
            }

            setForm(formNew);
        },
        [notChangeForm, form],
    );

    const onOpenModal = () => { };

    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = () => {
        history.push({
            pathname: location.state.pathname,
            state: { ...location.state },
        });
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    const generateKey = () => {
        dispatch(generateKeyRequest({
            id,
            callbackSuccess: token => {
                navigator.clipboard &&
                navigator.clipboard.writeText(token).then(
                    () => {
                        toast.info(t('copied_to_clipboard_success'));
                    },
                    error => {
                        toast.error(t('copied_to_clipboard_error', { error }));
                    },
                );
            }
        }))
    };

    const getContent = useCallback(
        () => {
            const params = {
                t,
                props,
                form,
                columns,
                name,
                id,
                error,
                title,
                canEdit,
                useDocuments,
                useHistory,
                onClose: handleClose,
                onChangeForm: handleChange,
                actionsFooter: getActionsFooter,
                actionsHeader: getActionsHeader,
                openModal: openModal,
                onOpenModal: onOpenModal,
                loading
            };
    
            const getContent = () => { return <UserTabs />; };
    
            return React.cloneElement(getContent(), params);
        },
        [form, error, columns, useDocuments, useHistory],
    );

    return (
        <React.Fragment>
            {getContent()}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </React.Fragment>
    );
};

export default UserCard;
