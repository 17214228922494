import React from 'react';
import FormField from "../formField";
import {
    NUMBER_TYPE,
    SELECT_TYPE,
    TEXT_TYPE
} from "../../../../constants/columnTypes";
import { Grid } from 'semantic-ui-react';
import { SETTINGS_TYPE_HIDE, SETTINGS_TYPE_SHOW } from '../../../../constants/formTypes';

const AdditionalCost = ({form = {}, onChange, settings, error, columnsConfig, index}) => {
    const costConditionSetting = settings && settings['costConditionId'];

    const fields = [
        {
            name: 'costConditionId',
            text: 'costConditionName',
            source: 'costConditions',
            type: SELECT_TYPE
        },
        {
            name: 'count',
            type: NUMBER_TYPE,
            isInteger: true,
            isDisabled: costConditionSetting === SETTINGS_TYPE_SHOW || costConditionSetting === SETTINGS_TYPE_HIDE
        },
        {
            name: 'cost',
            type: NUMBER_TYPE,
            isDisabled: costConditionSetting === SETTINGS_TYPE_SHOW || costConditionSetting === SETTINGS_TYPE_HIDE
        },
        {
            name: 'costConditionCode',
            type: TEXT_TYPE,
            isReadOnly: true
        }
    ];

    return (
        <Grid.Row className="field-row">
            {
                fields.map(column => (
                    <Grid.Column width={4}>
                        <FormField
                            {...column}
                            key={column.name}
                            value={form}
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={{[column.name]: error[`${column.name}_${index}`]}}
                            onChange={onChange}
                        />
                    </Grid.Column>
                ))
            }
        </Grid.Row>
    );
};

export default AdditionalCost;
