import { createSelector } from 'reselect';
import { postman } from '../utils/postman';
import { all, put, takeEvery } from 'redux-saga/effects';

const TYPE_API = 'notificationSettings';

//*  TYPES  *//

const GET_NOTIFICATIONS_SETTINGS_REQUEST = 'GET_NOTIFICATIONS_SETTINGS_REQUEST';
const GET_NOTIFICATIONS_SETTINGS_SUCCESS = 'GET_NOTIFICATIONS_SETTINGS_SUCCESS';
const GET_NOTIFICATIONS_SETTINGS_ERROR = 'GET_NOTIFICATIONS_SETTINGS_ERROR';

const EDIT_NOTIFICATION_SETTINGS_REQUEST = 'EDIT_NOTIFICATION_SETTINGS_REQUEST';
const EDIT_NOTIFICATION_SETTINGS_SUCCESS = 'EDIT_NOTIFICATION_SETTINGS_SUCCESS';
const EDIT_NOTIFICATION_SETTINGS_ERROR = 'EDIT_NOTIFICATION_SETTINGS_ERROR';

const CLEAR_NOTIFICATION_SETTINGS = 'CLEAR_NOTIFICATION_SETTINGS';

const DEFAULT_STATE = 'DEFAULT_STATE';

//*  INITIAL STATE  *//

const initial = {
    settings: {},
    progress: false,
    editProgress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload = {} }) => {
    switch (type) {
        case GET_NOTIFICATIONS_SETTINGS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_NOTIFICATIONS_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: payload,
                progress: false,
            };
        case GET_NOTIFICATIONS_SETTINGS_ERROR:
            return {
                ...state,
                settings: {},
                progress: false,
            };
        case EDIT_NOTIFICATION_SETTINGS_REQUEST:
            const { params = {} } = payload;

            return {
                ...state,
                editProgress: {
                    type: params.type
                },
            };
        case EDIT_NOTIFICATION_SETTINGS_SUCCESS:
        case EDIT_NOTIFICATION_SETTINGS_ERROR:
            return {
                ...state,
                editProgress: false,
            };
        case CLEAR_NOTIFICATION_SETTINGS:
            return {
                ...state,
                ...initial,
            };
        case DEFAULT_STATE:
            return {
                ...initial
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getNotificationsSettingsRequest = payload => {
    return {
        type: GET_NOTIFICATIONS_SETTINGS_REQUEST,
        payload,
    };
};

export const editNotificationSettingsRequest = payload => {
    return {
        type: EDIT_NOTIFICATION_SETTINGS_REQUEST,
        payload,
    };
};

export const clearNotificationsSettings = () => {
    return {
        type: CLEAR_NOTIFICATION_SETTINGS,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.notificationsSetting;

export const notificationsSettingSelector = createSelector(stateSelector, state => state.settings);

export const progressSelector = createSelector(stateSelector, state => state.progress);

export const editProgressSelector = createSelector(stateSelector, state => state.editProgress);

//*  SAGA  *//
export function* getNotificationsSettingsSaga({ payload }) {
    try {
        const result = yield postman.post(`${TYPE_API}/get`, payload);
        yield put({
            type: GET_NOTIFICATIONS_SETTINGS_SUCCESS,
            payload: result
        });
    } catch (e) {
        yield put({
            type: GET_NOTIFICATIONS_SETTINGS_ERROR,
            payload: e,
        });
    }
}

function* editNotificationSettingsSaga({ payload = {} }) {
    try {
        const { params, callbackSuccess} = payload;
        const result = yield postman.post(`/${TYPE_API}/edit`, params);
        yield put({
            type: EDIT_NOTIFICATION_SETTINGS_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_NOTIFICATION_SETTINGS_ERROR,
            payload: e,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(GET_NOTIFICATIONS_SETTINGS_REQUEST, getNotificationsSettingsSaga),
        takeEvery(EDIT_NOTIFICATION_SETTINGS_REQUEST, editNotificationSettingsSaga),
    ]);
}