import React from 'react';
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {Button} from "semantic-ui-react";

const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

const years = Array.from({length: 200}, (_, index) => 1900 + index);

const DateHeader = ({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => {
    const {t} = useTranslation();

    return <div className='date-header'>
        <Button
            size='mini'
            icon='angle left'
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
        />

        <select
            className='date-header__select date-header__year'
            value={moment(date).year()}
            onChange={({target: {value}}) => changeYear(value)}
        >
            {years.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>

        <select
            className='date-header__select date-header__month'
            value={months[moment(date).month()]}
            onChange={({target: {value}}) =>
                changeMonth(months.indexOf(value))
            }
        >
            {months.map((option) => (
                <option key={option} value={option}>
                    {t(option)}
                </option>
            ))}
        </select>

        <Button
            size='mini'
            icon='angle right'
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
        />
    </div>
}

export default DateHeader;