import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react';
import _ from 'lodash';
import { sortFunc } from '../../../utils/sort';

const Header = ({
    rolesList,
    role,
    changeRole,
    t,
    companiesList,
    company,
    changeCompany,
}) => {
    let [isEdit, setIsEdit] = useState(false);
    let [initialValue, setInitialValue] = useState('');
    let [currentValue, setCurrentValue] = useState('');

    const rolesListOptions = useMemo(
        () => [
            /*{ key: 'any_role', value: 'null', text: t('any_role') },*/
            ...rolesList.map((x) => ({ key: x.name, value: x.value, text: x.name })),
        ],
        [rolesList],
    );
    const companyListOptions = useMemo(
        () => [...companiesList.map((x) => ({ key: x.name, value: x.value, text: x.name }))],
        [companiesList],
    );

    const startEdit = () => {
        setIsEdit(true);
    };

    return (
        <Menu className="field-settings-menu">
            <Menu.Item>
                <span>
                    <label>{t('notifications')}</label>
                </span>
            </Menu.Item>
            <Menu.Item>
                <span>
                    <label>{`${t('ЮЛ')}: `}</label>
                    <Dropdown
                        value={company}
                        inline
                        options={companyListOptions}
                        onChange={changeCompany}
                    />
                </span>
            </Menu.Item>
            <Menu.Item>
                <span>
                    <label>{`${t('role')}: `}</label>
                    <Dropdown
                        value={role}
                        inline
                        options={rolesListOptions}
                        onChange={changeRole}
                    />
                </span>
            </Menu.Item>
        </Menu>
    );
};

export default React.memo(Header);
