import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Icon, Menu } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    dataLoadingMenuSelector,
    dictionariesHeaderSelector,
    dictionariesMenuSelector,
    gridsMenuSelector,
    otherMenuSelector,
    reportsMenuSelector,
    roleSelector,
    userLoginSelector,
    userNameSelector,
    instructionsSelector,
    companySettingsMenuSelector,
    getSettingsCompaniesRequest, userPermissionsSelector
} from '../../ducks/profile';
import { isAuthSelector, logoutRequest } from '../../ducks/login';
import { downloadDocumentRequest } from '../../ducks/documents';
import './style.scss';
import { DICTIONARY_LIST_LINK, GRID_LIST_LINK, LOADER_LINK, PROFILE_LINK, REPORT_LINK } from '../../router/links';
import {
    dataLoadingRequest,
    getInstructionRequest,
    progressLoadingDataSelector,
    templateUploadRequest,
} from '../../ducks/loadingData';
import { downloader } from "../../utils/postman";
import downloadFile from "../../utils/downloadFile";
import TemplateSettings from "../LoadingData/TemplateSettings";

const Header = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const fileUploader = useRef(null);

    const grids = useSelector(state => gridsMenuSelector(state));
    const dictionariesList = useSelector(state => dictionariesMenuSelector(state, t));
    const dictionariesMenu = useSelector(state => dictionariesHeaderSelector(state,t));
    const otherMenu = useSelector(state => otherMenuSelector(state));
    const companySettingMenu = useSelector(state => companySettingsMenuSelector(state));
    const userPermissions = useSelector(state => userPermissionsSelector(state));
    const dataLoadingMenu = useSelector(state => dataLoadingMenuSelector(state));
    const reportMenu = useSelector(state => reportsMenuSelector(state));
    const userLogin = useSelector(state => userLoginSelector(state));
    const userName = useSelector(state => userNameSelector(state));
    const userRole = useSelector(state => roleSelector(state));
    const isAuth = useSelector(state => isAuthSelector(state));
    const loading = useSelector(state => progressLoadingDataSelector(state));
    const instructions = useSelector(state => instructionsSelector(state));

    let [activeItem, setActiveItem] = useState(location.pathname);
    let [currentTypeApi, setCurrentTypeApi] = useState(null);
    let [openTemplateSettings, setOpenTemplateSettings] = useState({ open: false });

    useEffect(() => {
        document.title = t("front_title");
     }, []);

    useEffect(() => {
        isAuth && dispatch(getSettingsCompaniesRequest());
    }, []);

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location.pathname]);

    const logOut = () => {
        dispatch(logoutRequest());
    };

    const handleOpenProfile = () => {
        history.push(PROFILE_LINK);
    };

    const handleClickLoadData = (type, typeApi, fileName) => {
        if (type === 'unloading') {
            dispatch(templateUploadRequest({
                typeApi
            }));
        } else if (type === 'loading') {
            setCurrentTypeApi(typeApi);
            fileUploader && fileUploader.current.click();
        } else if (type === 'instruction') {
            dispatch(getInstructionRequest({
                fileName
            }))
        } else if (type === 'template_settings') {
            setOpenTemplateSettings({
                open: true,
                typeApi
            });
        } else if (type === 'loader') {
            history.push(LOADER_LINK.replace(':type', typeApi));
        }
    };

    const handleClickReport = (type) => {
        history.push(REPORT_LINK.replace(':type', type));
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);

        dispatch(
            dataLoadingRequest({
                form: data,
                typeApi: currentTypeApi
            }),
        );
        setCurrentTypeApi(null);
        fileUploader.current.value = null;
    };

    return (
        <>
            {isAuth ? (
                <header>
                    <Menu pointing secondary fixed="top" style={{ paddingLeft: '12px' }}>
                        {/*<Menu.Item>LOGO</Menu.Item>*/}
                        {grids &&
                            grids.map(item => (
                                <Menu.Item
                                    className="large"
                                    key={item.name}
                                    as={Link}
                                    to={GRID_LIST_LINK.replace(':name', item.name)}
                                    name={item.name}
                                    active={activeItem.includes(item.name)}
                                >
                                    {item.displayName}
                                </Menu.Item>
                            ))}
                        {dictionariesMenu && dictionariesMenu.length
                            ? dictionariesMenu.map(item => (
                                <Menu.Item
                                    className="large"
                                    key={item.name}
                                    as={Link}
                                    to={item.link}
                                    name={item.name}
                                    active={activeItem.includes(item.link)}
                                >
                                    {item.displayName}
                                </Menu.Item>
                            ))
                            : null}
                        {otherMenu && otherMenu.length
                            ?
                            <Menu.Menu>
                                <Dropdown text={t('Settings')} item>
                                    <Dropdown.Menu>
                                        {otherMenu.map(item => (
                                            <Dropdown.Item
                                                key={item.name}
                                                as={Link}
                                                to={item.link}
                                                active={activeItem.includes(item.link)}
                                                name={item.name}
                                            >{t(item.name)}</Dropdown.Item>
                                        ))}
                                        {userPermissions.includes(138) && <Dropdown text={t('companySettings')} item>
                                            <Dropdown.Menu>
                                                { companySettingMenu.map(settingItem => (
                                                    <Dropdown.Item
                                                        key={settingItem.name}
                                                        as={Link}
                                                        to={settingItem.link}
                                                       // active={activeItem.includes(settingItem)}
                                                        name={settingItem.name}
                                                    >{t(settingItem.name)}</Dropdown.Item>))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                            : null}
                        {(dictionariesList && (dictionariesList.groups.length > 0 || dictionariesList.others.length > 0)) ? (
                            <Menu.Menu>
                                <Dropdown
                                    text={t('dictionaries')}
                                    item
                                    className={`${[
                                        ...(dictionariesList.groups || []).map(gr => gr.items).flat(), ...dictionariesList.others
                                    ].some(x => activeItem.includes(x.link)) && 'superActive'}`}
                                >
                                    <Dropdown.Menu>
                                        {dictionariesList.groups.map(group => (
                                                <Dropdown
                                                    item
                                                    className="link item"
                                                    key={group.name}
                                                    name={group.name}
                                                    text={t(group.name)}
                                                >
                                                    <Dropdown.Menu>
                                                        {group.items.map(item => (
                                                            <Dropdown.Item
                                                                key={item.name}
                                                                as={Link}
                                                                to={item.link}
                                                                active={activeItem.includes(item.link)}
                                                                name={item.name}
                                                            >
                                                                {item.displayName}
                                                            </Dropdown.Item>))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            ))
                                        }
                                        {dictionariesList.others.map(item => {
                                            return (
                                                <Dropdown.Item
                                                    key={item.name}
                                                    as={Link}
                                                    to={item.link}
                                                    active={activeItem.includes(item.link)}
                                                    name={item.name}
                                                >
                                                    {item.displayName}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {reportMenu && reportMenu.length ? (
                            <Menu.Menu>
                                <Dropdown text={t('Reports')} item>
                                    <Dropdown.Menu>
                                        {reportMenu.map(item => (
                                            <Dropdown.Item
                                                key={item.name}
                                                name={item.name}
                                                text={t(item.name)}
                                                onClick={() => handleClickReport(item.type)}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {dataLoadingMenu && dataLoadingMenu.length ? (
                            <Menu.Menu>
                                <Dropdown style={{whiteSpace: 'nowrap'}} text={t('data_loading')} item loading={loading}>
                                    <Dropdown.Menu>
                                        {dataLoadingMenu.map(item => (
                                            <Dropdown
                                                item
                                                className="link item"
                                                key={item.name}
                                                name={item.name}
                                                text={t(item.name)}
                                            >
                                                <Dropdown.Menu>
                                                    {item.items.map(childItem => (
                                                        <Dropdown.Item
                                                            key={childItem.name}
                                                            name={childItem.name}
                                                            text={t(childItem.name)}
                                                            onClick={() =>
                                                                handleClickLoadData(
                                                                    childItem.type,
                                                                    item.typeApi,
                                                                    childItem.fileName
                                                                )
                                                            }
                                                        />))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}

                        {instructions && instructions.length > 0 ? (<div className="header-support header-support_info">
                            <Dropdown
                                trigger={<Button style={{padding: '4px'}} icon='info' size="mini"/>}
                                icon={null}
                            >
                                <Dropdown.Menu className='menu-scroll'>
                                    {instructions && instructions.map(item => (
                                        <Dropdown.Item
                                            key={item.name}
                                            name={item.name}
                                            text={t(item.name)}
                                            onClick={() => {
                                                dispatch(downloadDocumentRequest({id: item.value}))
                                            }}
                                        />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>) : null}

                        <div className="header-support">
                            <Dropdown
                                direction='left'
                                className='compact-item'
                                trigger={<Icon name="question circle" />}
                                icon={null}
                                item
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header>
                                        <a href="mailto:support.abinbev@artlogics.me">support.abinbev@artlogics.me</a>
                                    </Dropdown.Header>
                                    <Dropdown.Header>{t('support_work_time')}</Dropdown.Header>
                                </Dropdown.Menu>
                            </Dropdown>

                            {userName && userRole ? (
                                <Menu.Menu>
                                    <Dropdown text={userLogin} item direction='left'>
                                        <Dropdown.Menu>
                                            <Dropdown.Header>ФИО: {userName}</Dropdown.Header>
                                            <Dropdown.Header>Роль: {userRole}</Dropdown.Header>
                                            <Dropdown.Item onClick={handleOpenProfile}>
                                                {t('profile_settings')}
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={logOut}>
                                                {t('exit')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                            ) : null}
                        </div>
                    </Menu>
                </header>
            ) : null}
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={onFilePicked}
            />
            <TemplateSettings {...openTemplateSettings} onClose={() => setOpenTemplateSettings({ open: false })} />
        </>
    );
};
Header.propTypes = {};

export default Header;
