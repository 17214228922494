import React, {useMemo} from 'react';
import Field from '../../../../components/BaseComponents';
import { SETTINGS_TYPE_SHOW } from '../../../../constants/formTypes';

const FormField = props => {
    const { form = {}, columnsConfig = {}, error = {}, settings = {}, name } = props;

    const newColumns = useMemo(() => {
        let newColumn = {
            ...columnsConfig[name],
        };

        let sourceParams = {};
        if (columnsConfig[name] && columnsConfig[name].dependencies && columnsConfig[name].dependencies.length) {
            columnsConfig[name].dependencies.forEach(item => {
                sourceParams = {
                    ...sourceParams,
                    [item]:
                        form[item] && typeof form[item] === 'object'
                            ? form[item].value
                            : form[item],
                };
            });
        }

        if (form.companyId && form.companyId.value) {
            sourceParams.companyId = form.companyId.value;
        }

        if (sourceParams)
        {
            newColumn = {
                ...newColumn,
                sourceParams,
            };
        }

        return newColumn;

    }, [columnsConfig, form]);


    return (
        <Field
            {...newColumns}
            {...props}
            key={name}
            value={form[name]}
            error={error[name]}
            settings={settings[name] || SETTINGS_TYPE_SHOW}
        />
    );
};

export default React.memo(FormField);
